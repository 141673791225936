import React, { useState } from "react";
import { Link } from "react-router-dom";

//Icons
import { IoMenu } from "react-icons/io5";

//Images
import logo from "./../../assets/logo.png";
import spain from "./../../assets/spain.png";
import brazil from "./../../assets/brazil.png";
import usa from "./../../assets/united-states.png";

import { language } from "./../../_constants";

//styles
import "./styles.scss";

const Menu = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const getLink = (lang) => {
        let path = window.location.pathname;
        let current = path.substring(path.lastIndexOf('/'), path.length);
        return `/${lang}${current}`;
    }

    const lang = language[props.language] ? language[props.language] : language['pt-br'];

    return (
        <header className="header-main">
            <h2 className="hidden">Topo</h2>
            <div className="submenu-header">
                <div className="content-subheader">
                    <Link to={getLink('pt-br')} className={`btn-flag ${!props.language || props.language === 'pt-br' ? 'selected' : ''}`}>
                        <img src={brazil} alt="PT-BR" width="25" height="25" />
                    </Link>
                    <Link to={getLink('es')} className={`btn-flag ${props.language === 'es' ? 'selected' : ''}`}>
                        <img src={spain} alt="ES" width="25" height="25" />
                    </Link>
                    <Link to={getLink('en')} className={`btn-flag ${props.language === 'en' ? 'selected' : ''}`}>
                        <img src={usa} alt="EN" width="25" height="25" />
                    </Link>
                </div>
            </div>
            <div className="content-header">
                <Link to="/">
                    <img src={logo} width="170" height="32" alt="Sucus Brasil" />
                </Link>
                <nav className={`menu-main ${!menuOpen && 'closed'}`}>
                    <h2 className="title-menu">{lang.titleMenu}</h2>
                    <Link className="item-menu" to="home">{lang.menuHome}</Link>
                    <Link className="item-menu" to="sobrenos">{lang.menuAbout}</Link>
                    <Link className="item-menu" to="produtos">{lang.menuProducts}</Link>
                    <Link className="item-menu" to="contato">{lang.menuContact}</Link>

                    <button onClick={() => setMenuOpen(!menuOpen)} className="btn-menu">
                        {menuOpen ? lang.closeMenu : <IoMenu size={32} />}
                    </button>
                </nav>
            </div>
        </header>
    );
}

export default Menu;
