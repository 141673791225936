import React from "react";
import Footer from "../../_components/Footer";
import Menu from "../../_components/Menu";
import Features from "../../_components/Features";
import Seasonality from "../../_components/Seasonality";
import { language } from "../../_constants";
import { Carousel } from 'react-responsive-carousel';

//styles
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.scss";

//banners
import banner1 from "../../assets/banners/banner1.png";
//import banner2 from "../../assets/banners/banner2.png";
//import banner3 from "../../assets/banners/banner3.png";

const Home = (props) => {
    const lang = language[props.match.params.lang] ? language[props.match.params.lang] : language['pt-br'];

    return (
        <>
            <Menu language={props.match.params.lang} />
            <section className="banner-home">
                <Carousel showIndicators={false} autoPlay={true} showArrows={false} showStatus={false} infiniteLoop={true} showThumbs={false} dynamicHeight={true} centerMode={true} centerSlidePercentage={100}>

                    <div>
                        <div className="content-banner">
                            <div className="box-text-banner">
                                <h2>{lang.titleBanner}</h2>
                            </div>
                        </div>
                        <img src={banner1} alt="Banner 1" />
                    </div>
                </Carousel>
            </section>
            <section className="box-fruits">
                <h2>{lang.sloganHome}</h2>
            </section>
            <Features language={props.match.params.lang} />
            <section className="box-slogan">
                <h3>{lang.letterHome}</h3>
            </section>
            <Seasonality language={props.match.params.lang} />
            <Footer language={props.match.params.lang} />
        </>
    );
}

export default Home;
