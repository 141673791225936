import React from "react";
import Dropdown from 'react-dropdown';

//styles
import 'react-dropdown/style.css';
import BoxProduct from "../BoxProduct";
import Modal from "../Modal";
import { fruits } from "./data";
import "./styles.scss";

import { language } from "./../../_constants";

const Seasonality = (props) => {

    const lang = language[props.language] ? language[props.language] : language['pt-br'];

    const [month, setMonth] = React.useState((new Date()).getMonth());
    const [showProduct, setShowProduct] = React.useState(false);
    const [product, setProduct] = React.useState({});

    return (
        <section className="seasonality">
            <div className="box-title-seasonality">
                <h2>{lang.titleSazonality}</h2>
                <Dropdown className="dropdown-month" options={lang.months} onChange={({ value }) => setMonth(value)} value={lang.months[month]} />
            </div>


            <div className="list-products-seasonality">
                {
                    fruits[month].map((fruit, idx) => <div key={`fruit-${idx}`} className="item-product">
                        <BoxProduct
                            onClick={() => {
                                setProduct(fruit);
                                setShowProduct(true);
                            }}
                            name={fruit.name}
                            image={fruit.image}
                            btnMore={lang.btnMore}
                            showButton={false}
                        />

                    </div>)
                }
            </div>

            <Modal
                isOpen={showProduct}
                onRequestClose={() => setShowProduct(false)}
            >
                <h3>{product.name}</h3>
            </Modal>
        </section>
    );
}

export default Seasonality;
