import React from "react";
import Footer from "./../../_components/Footer";
import Menu from "./../../_components/Menu";
import { language } from "../../_constants";

//styles
import "./styles.scss";

//Icons
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Contact = (props) => {
    const lang = language[props.match.params.lang] ? language[props.match.params.lang] : language['pt-br'];

    return (
        <>
            <Menu language={props.match.params.lang} />
            <section className="banner">
                <div className="box-contacts">
                    <h2 className="title-page">{lang.titleContact}</h2>
                    <p>
                        {lang.textContact}
                    </p>
                    <ul className="list-contacts">
                        <li>
                            <FaPhoneAlt className="icon-contact" />
                            +55 88 2157-0079
                        </li>
                        <li>
                            <FaEnvelope className="icon-contact" />
                            contato@sucusbrasil.com.br
                        </li>
                        <li>
                            <FaMapMarkerAlt className="icon-contact" />
                            Rua Catulo da Paixão Cearense, 175, 18º andar,
                            Pátio Cariri Corporate, Sala 1804, Triângulo
                            Juazeiro do Norte  - CE, Brasil
                        </li>
                    </ul>
                </div>
            </section>
            <section className="faq">
                <h3>{lang.titleFAQ}</h3>

                <div className="item-faq">
                    <h4>{lang.questionsFAQ[0].question}</h4>
                    <p dangerouslySetInnerHTML={{ __html: lang.questionsFAQ[0].response }}></p>
                </div>
                <div className="item-faq">
                    <h4>{lang.questionsFAQ[1].question}</h4>
                    <p dangerouslySetInnerHTML={{ __html: lang.questionsFAQ[1].response }}></p>
                </div>
                <div className="item-faq">
                    <h4>{lang.questionsFAQ[2].question}</h4>
                    <p dangerouslySetInnerHTML={{ __html: lang.questionsFAQ[2].response }}></p>
                </div>
                <div className="item-faq">
                    <h4>{lang.questionsFAQ[3].question}</h4>
                    <p dangerouslySetInnerHTML={{ __html: lang.questionsFAQ[3].response }}></p>
                </div>
            </section>
            <Footer language={props.match.params.lang} />
        </>
    );
}

export default Contact;
