import React from "react";

import { IoClose } from "react-icons/io5";

//styles
import "./styles.scss";

const Modal = (props) => {

    const onClose = (evt) => {
        if (typeof props.onRequestClose === 'function') {
            props.onRequestClose();
        }
    }

    return (
        <div className={`modal ${!props.isOpen && 'hidden'}`}>
            <div className="box-modal">
                <button className="btn-close" id="btn-close" onClick={onClose}>
                    <IoClose />
                </button>
                {props.children}
            </div>
            <div id="shadow" className="shadow-modal" onClick={onClose} />
        </div>
    );
}

export default Modal;
