import React from "react";

//styles
import "./styles.scss";

const BoxProduct = (props) => {

    return (
        <div className="box-product">
            <img src={props.image} alt="Foto da Fruta" height="100" />
            <h4>{props.name}</h4>
            {props.showButton && <button onClick={props.onClick} className="btn-more">{props.btnMore}</button>}
        </div>
    );
}

export default BoxProduct;
