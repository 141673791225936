import React from "react";
import Footer from "./../../_components/Footer";
import Menu from "./../../_components/Menu";
import { language } from "../../_constants";

//styles
import "./styles.scss";


const About = (props) => {
    const lang = language[props.match.params.lang] ? language[props.match.params.lang] : language['pt-br'];
    return (
        <>
            <Menu language={props.match.params.lang} />
            <header className="banner-about">
                <div className="box-about">
                    <h2 className="title-page">{lang.titleAbout}</h2>
                    <p>
                        {lang.descAbout}
                        <br /><br />
                        {lang.desc2About}
                    </p>
                </div>
            </header>
            <section className="box-company">
                <div className="box-details-company">
                    <h3>{lang.missionTitle}</h3>
                    <p>
                        {lang.missionDesc}
                    </p>
                </div>
                <div className="box-details-company">
                    <h3>{lang.visionTitle}</h3>
                    <p>
                        {lang.visionDesc}
                    </p>
                </div>
                <div className="box-details-company">
                    <h3>{lang.valuesTitle}</h3>
                    <p>
                        {lang.valuesDesc}
                    </p>
                </div>
            </section>
            <section className="box-map">
                <h3>{lang.titleWhereAbout}</h3>
                <iframe title="Mapa" height="350" id="gmap_canvas" src="https://maps.google.com/maps?q=Frutus%20Brasil&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </section>
            <Footer language={props.match.params.lang} />
        </>
    );
}

export default About;
