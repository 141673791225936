import React from "react";
import { Link } from "react-router-dom";

//styles
import "./styles.scss";

import logoWhite from "./../../assets/logo_white.png";
import iacc from "./../../assets/iacc.png";
import ecocert from "./../../assets/certificacoes/ecocert.png";
import fda from "./../../assets/certificacoes/fda.png";
import global from "./../../assets/certificacoes/global.png";
import haccp from "./../../assets/certificacoes/haccp.png";

import { language } from "./../../_constants";

const Footer = (props) => {

    const lang = language[props.language] ? language[props.language] : language['pt-br'];

    return (
        <footer className="footer">
            <div className="box-footer">
                <div className="info-footer">
                    <h3 className="title-footer">
                        {lang.titleCertifieds}
                    </h3>
                    <div className="box-certified">
                        <img src={fda} className="img-cert" width="70" alt="FDA" />
                        <img src={global} className="img-cert" width="70" alt="Global" />
                        <img src={haccp} className="img-cert" width="70" alt="HACCP" />
                        <img src={ecocert} className="img-cert" width="70" alt="EcoCert" />
                    </div>
                </div>

                <div className="info-footer">
                    <h3 className="title-footer">
                        {lang.titleWhere}
                    </h3>
                    <p>
                        Rua Catulo da Paixão Cearense, 175, 18º andar,
                        Pátio Cariri Corporate, Sala 1804, Triângulo.
                        Juazeiro do Norte  - CE,
                        Brasil
                    </p>
                    <b>
                        +55 88 2157-0079
                    </b><br />
                    <b>
                        contato@sucusbrasil.com.br
                    </b>
                </div>
            </div>
            <div className="box-footer">
                <div className="info-footer iacc">
                    <img src={iacc} width="100" height="100" alt="iacc" />
                    <div className="box-iacc">
                        <h3 className="title-footer">{lang.titleIACC}</h3>
                        <span>
                            {lang.descIACC}
                        </span>
                        <a href="https://app.payplace.com.br/iacc/DOACAO/iacc" target="_blank" rel="noreferrer" className="link-iacc">{lang.btnIACC}</a>
                    </div>
                </div>

                <div className="info-footer">
                    <h3 className="title-footer">
                        {lang.titleSiteMap}
                    </h3>
                    <div className="box-lists">
                        <ul className="list-links">
                            <li>
                                <Link to="home">{lang.menuHome}</Link>
                            </li>
                            <li>
                                <Link to="sobrenos">{lang.menuAbout}</Link>
                            </li>
                            <li>
                                <Link to="produtos">{lang.menuProducts}</Link>
                            </li>
                            <li>
                                <Link to="contato">{lang.menuContact}</Link>
                            </li>
                        </ul>
                        <img src={logoWhite} width="170" height="32" alt="Sucus Brasil" className="logowhite" />
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <span>
                    {lang.textCopy}
                </span>
            </div>
        </footer>
    );
}

export default Footer;
