
import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { history } from "./_history";

//Pages Site
import Home from "./_pages/Home";
import About from "./_pages/About";
import Products from "./_pages/Products";
import Contact from "./_pages/Contact";

//Styles
import "./_styles/App.scss";

const App = () => {
    return (
        <Router history={history}>
            <div className="page-content">
                <Switch>
                    <Route exact path="/sobrenos" component={About} />
                    <Route exact path="/produtos" component={Products} />
                    <Route exact path="/contato" component={Contact} />
                    <Route exact path="/home" component={Home} />

                    <Route exact path="/:lang/sobrenos" component={About} />
                    <Route exact path="/:lang/produtos" component={Products} />
                    <Route exact path="/:lang/contato" component={Contact} />
                    <Route exact path="/:lang/home" component={Home} />
                    <Route exact path="/:lang" component={Home} />
                    <Route path="/" component={Home} />
                </Switch>
            </div>
            <div className="seeds" />
        </Router>
    );
}

export default App;

