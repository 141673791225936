//IMAGENS
import goiaba from "./../assets/fruits/goiaba.jpg";
import laranja from "./../assets/fruits/laranja.jpg";
import melaoSapo from "./../assets/fruits/melao_sapo.jpg";
import melaoAmarelo from "./../assets/fruits/melao_amarelo.jpg";
import melancia from "./../assets/fruits/melancia.jpg";
import maracuja from "./../assets/fruits/maracuja.jpg";
import mamao from "./../assets/fruits/mamao.png";
import aboboraButternut from "./../assets/fruits/abobora_butternut.jpg";
import aboboraSergipana from "./../assets/fruits/abobora_sergipana.jpg";
import banana from "./../assets/fruits/banana.jpg";
import maca from "./../assets/fruits/maca.jpg";
import pera from "./../assets/fruits/pera.jpg";
import abacaxi from "./../assets/fruits/abacaxi.jpg";
import limao from "./../assets/fruits/limao.jpg";
import limao2 from "./../assets/fruits/limao2.png";
import batata_doceUruguaia from "./../assets/fruits/batata_doceUruguaia.jpg";
import batataDoce from "./../assets/fruits/batata_doce.jpg";
import manga from "./../assets/fruits/manga.jpg";
import mangaAtaulfo from "./../assets/fruits/mangaAtaulfo.png";
import mangaPalmer from "./../assets/fruits/mangaPalmer.png";
import mangaKeitt from "./../assets/fruits/mangaKeitt.jpg";
import mangaKent from "./../assets/fruits/mangaKent.png";
import acerola from "./../assets/fruits/acerola.jpg";


//Fichas - IN NATURA
import fichaAbacaxi from './../assets/fichas/In Natura/FICHA TÉCNICA - ABACAXI.pdf';
import fichaAboboraButternut from './../assets/fichas/In Natura/FICHA TÉCNICA - ABÓBORA MANTEIGA-BUTTERNUT.pdf';
import fichaAboboraSergipana from './../assets/fichas/In Natura/FICHA TÉCNICA - ABÓBORA SERGIPANA.pdf';
import fichaBanana from './../assets/fichas/In Natura/FICHA TÉCNICA - BANANA NANICA.pdf';
import fichaBatataDoceCanadense from './../assets/fichas/In Natura/FICHA TÉCNICA - BATATA DOCE CANADENSE.pdf';
import fichaLimaoSiciliano from './../assets/fichas/In Natura/FICHA TÉCNICA - LIMÃO SICILIANO.pdf';
import fichaMaca from './../assets/fichas/In Natura/FICHA TÉCNICA - MAÇÃ.pdf';
import fichaMangaAtaulfo from './../assets/fichas/In Natura/FICHA TÉCNICA - MANGA ATAULFO.pdf';
import fichaMangaKeitt from './../assets/fichas/In Natura/FICHA TÉCNICA - MANGA KEITT.pdf';
import fichaMangaKent from './../assets/fichas/In Natura/FICHA TÉCNICA - MANGA KENT.pdf';
import fichaMangaPalmer from './../assets/fichas/In Natura/FICHA TÉCNICA - MANGA PALMER.pdf';
import fichaMangaTommy from './../assets/fichas/In Natura/FICHA TÉCNICA - MANGA TOMMY.pdf';
import fichaPera from './../assets/fichas/In Natura/FICHA TÉCNICA - PERA.pdf';
import fichaBatataDoceArapey from './../assets/fichas/In Natura/FICHA TÉCNICA- BATATA DOCE ARAPEY.pdf';
import fichaLimaoTahiti from './../assets/fichas/In Natura/FICHA TÉCNICA- LIMÃO TAITI.pdf';
import fichaMelancia from './../assets/fichas/In Natura/FICHA TÉCNICA- MELANCIA.pdf';
import fichaMelaoAmarelo from './../assets/fichas/In Natura/FICHA TÉCNICA- MELÃO AMARELO.pdf';
import fichaMelaoSapo from './../assets/fichas/In Natura/FICHA TÉCNICA- MELÃO PELE DE SAPO.pdf';


//Fichas - SUCO/POLPA
import fichaGoiabaOrg from './../assets/fichas/Suco-Polpa/Ficha Técnica  - Goiaba Organico Asséptico.pdf';
import fichAbacaxiOrg from './../assets/fichas/Suco-Polpa/Ficha Técnica - Abacaxi Organico.pdf';
import fichaAcerolaInt from './../assets/fichas/Suco-Polpa/Ficha Técnica - Acerola Integral.pdf';
import fichaAcerolaOrg from './../assets/fichas/Suco-Polpa/Ficha Técnica - Acerola Orgânica.pdf';
import fichaLimaoConcentrado from './../assets/fichas/Suco-Polpa/Ficha Técnica - Limão Concentrado.pdf';
import fichaMangaOrg from './../assets/fichas/Suco-Polpa/Ficha Técnica - Manga Organico.pdf';
import fichaMangaTommyInt from './../assets/fichas/Suco-Polpa/Ficha Técnica - Manga Tommy integral asséptica.pdf';
import fichaMaracujaInt from './../assets/fichas/Suco-Polpa/Ficha Técnica - Maracuja Integral.pdf';
import fichaMaracujaOrg from './../assets/fichas/Suco-Polpa/Ficha Técnica - Maracuja Organico.pdf';
import fichaMamaoInt from './../assets/fichas/Suco-Polpa/Ficha Técnica - Polpa de Mamão Integral.pdf';
import fichaMamaoOrg from './../assets/fichas/Suco-Polpa/Ficha Técnica - Polpa de Mamão Orgânico.pdf';
import fichaMelanciaOrg from './../assets/fichas/Suco-Polpa/Ficha Técnica - Polpa Melancia Orgânica.pdf';
import fichaMangaPure1 from './../assets/fichas/Suco-Polpa/Ficha Técnica - Purê de Manga Asséptica Concentrada. BRIX 14-16 VARIEDADE TOMMY.pdf';
import fichaMangaPure2 from './../assets/fichas/Suco-Polpa/Ficha Técnica - Purê de Manga Asséptica Concentrada. BRIX 16-18 VARIEDADE PALMER.pdf';
//import fichaMangaPure3 from './../assets/fichas/Suco-Polpa/Ficha Técnica - Purê de Manga Asséptica Concentrada. BRIX 28-30 VARIEDADE PALMER.pdf';
//import fichaMangaPure4 from './../assets/fichas/Suco-Polpa/Ficha Técnica - Purê de Manga Asséptica Concentrada. BRIX 28-30 VARIEDADE TOMMY.pdf';
import fichaLaranjaConcentrado from './../assets/fichas/Suco-Polpa/Ficha Técnica - Suco de  Laranja Concentrado Congelado.pdf';
import fichaAbacaxiConcentrado from './../assets/fichas/Suco-Polpa/Ficha Técnica - Suco de Abacaxi Concentrado Congelado - 60°BRIX.pdf';
import fichaSucoLimao from './../assets/fichas/Suco-Polpa/Ficha Técnica - Suco de Limão.pdf';


export const numberWhatsApp = "(88) 99999-0000";
export const numberPhone = "(88) 99999-0000";


//MENU
export const language = {
    'pt-br': {
        //Menu
        titleMenu: 'Menu',
        closeMenu: 'Fechar Menu',
        menuHome: 'Home',
        menuAbout: 'Sobre nós',
        menuProducts: 'Produtos',
        menuContact: 'Contato',

        //Home
        titleBanner: 'O melhor da fruta para o mundo!',
        sloganHome: 'Trabalhamos com Polpa de Fruta e Frutas In Natura',
        subSloganHome: 'In Natura, Suco ou Polpa',
        letterHome: 'O melhor da fruta para o mundo!',

        //Sobre
        titleAbout: 'Sobre Nós',
        descAbout: 'Somos uma empresa fundada em 2014, atuante na indústria e comércio de suco prontos, polpas de frutas, frutas in natura e derivados.',
        desc2About: 'Temos nossa sede administrativa na cidade de Juazeiro do Norte – CE, onde são feitas as gestões de recursos humanos, administrativas e técnicas.',
        missionTitle: 'Missão',
        missionDesc: 'Atender ao mercado interno e externo consumidor de sucos, polpas e frutas in natura com produtos de qualidade, primando pela satisfação total dos nossos clientes.',
        visionTitle: 'Visão',
        visionDesc: 'Ser líder e referência na produção, comércio e distribuição de sucos, polpas e frutas in natura.',
        valuesTitle: 'Valores',
        valuesDesc: 'Responsabilidade, Qualidade e Confiança.',
        titleWhereAbout: 'Onde Estamos',

        //Produtos
        titleProducts: 'Nossos Produtos',
        box1Products: 'In Natura',
        box2Products: 'Suco/Polpa',

        //Contato
        titleContact: 'Contato',
        textContact: 'Escolha como você prefere entrar em contato conosco, estamos prontos para lhe atender.',
        titleFAQ: 'Perguntas Frequentes',
        questionsFAQ: [
            {
                question: 'Onde fica a sede administrativa da Sucus?',
                response: 'Nossa sede fica na cidade de <b>Juazeiro do Norte-CE</b>.',
            },
            {
                question: 'Quais Países vocês atendem?',
                response: 'Atendemos o mercado <b>Europeu, Norte Americano e Sul Americano</b>.',
            },
            {
                question: 'Vocês trabalham com frutas in natura?',
                response: 'Sim, trabalhamos com <b>exportações, importações e com o mercado interno</b>.',
            },
            {
                question: 'Com quais frutas vocês trabalham?',
                response: 'Trabalhamos com as seguintes <b>frutas in natura</b>: Abacaxi, Abóbora Butternut Squash, Abóbora Sergipana, Banana Nanica, Batata Doce Arapey, Batata Doce Canadense, Limão Siciliano, Limão Taiti, Maçã, Manga Ataulfo, Manga Kent, Manga Palmer, Manga Tommy, Melancia, Melão Amarelo, Melão Pele De Sapo e Pera. <br/><br/><b>Suco e Polpa</b> trabalhamos com os seguintes: Abacaxi Integral, Abacaxi Orgânico, Acerola Orgânica, Acerola Convencional, Goiaba Orgânica, Goiaba Convencional, Laranja Concentrada, Laranja Integral, Limão Concentrado, Limão Integral, Manga Orgânica, Manga Convencional, Mamão Orgânico, Mamão Convencional, Maracujá Convencional e Maracujá Orgânico.',
            }
        ],

        //Proccess
        titleProccess: 'Processo',
        descProccess: 'Acompanhamos todas as etapas do processo produtivo, da colheita da fruta até o produto final. Desta forma oferecemos um produto dentro do mais alto padrão de qualidade para os nossos clientes.',
        titleFruits: 'Frutas Selecionadas',
        descFruits: 'Nossas frutas são selecionadas e tratadas, com elevadas condições de higiene e sanitização, atendendo aos padrões higiênico sanitários, com base nas normas e legislações internacionais de segurança alimentar e garantia de qualidade.',
        titleQuality: 'Produto de Qualidade',
        descQuality: 'Nossos colaboradores e fornecedores de matéria-prima, insumos e serviços são conscientizados do compromisso da empresa em dar total garantia de qualidade ao nosso produto final.',
        sloganProccess: 'A execução de cada uma das operações é feita com foco no atendimento dos requisitos para a <b>EXPORTAÇÃO E MERCADO INTERNO</b>.',

        //Sazonalidade
        titleSazonality: 'Sazonalidade',
        btnMore: 'Saiba Mais',
        months: [
            { value: 0, label: 'Janeiro' },
            { value: 1, label: 'Fevereiro' },
            { value: 2, label: 'Março' },
            { value: 3, label: 'Abril' },
            { value: 4, label: 'Maio' },
            { value: 5, label: 'Junho' },
            { value: 6, label: 'Julho' },
            { value: 7, label: 'Agosto' },
            { value: 8, label: 'Setembro' },
            { value: 9, label: 'Outubro' },
            { value: 10, label: 'Novembro' },
            { value: 11, label: 'Dezembro' }
        ],

        //Rodapé
        titleCertifieds: 'Certificações',
        titleWhere: 'Onde Estamos',
        titleIACC: 'Doe para o IACC',
        descIACC: 'Instituto de Apoio à Criança com Cancer',
        btnIACC: 'Doar',
        titleSiteMap: 'Mapa do site',
        textCopy: 'Copyright © 2021 Sucus Brasil | Todos os direitos reservados',

        //FRUTAS
        titleBtnFruits: 'Ficha(s) Técnica(s)',
        fruits: [
            [
                {
                    name: "Abacaxi",
                    image: abacaxi,
                    describe: `
                    <b>O Ananás ou abacaxi (Ananas comosus)</b> é uma infrutescência tropical produzida pela planta de mesmo nome. O abacaxi é uma fruta típica de países tropicais e subtropicais, portanto, não se adapta em regiões de clima frio, costuma florescer na época do inverno mas é ofertado no mercado durante o ano todo, em razão de ser plantado em diferentes locais, com diferentes técnicas de cultivo, incluindo diversos tipos de mudas e controle químico do florescimento. Os principais países produtores de abacaxi, segundo a Organização das Nações Unidas para a Alimentação e Agricultura (2008), são <b>o Brasil, a Tailândia, as Filipinas, a Costa Rica, a República Popular da China, a Índia e a Indonésia.</b>
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada e grossa<br/>
                    <b>Polpa:</b> levemente ácida<br/>
                    <b>Semente:</b> Não possui sementes<br/>
                    <b>Vitaminas:</b> rica em vitamina C, B1 e A.`,
                    attachments: [
                        {
                            name: 'Abacaxi',
                            file: fichaAbacaxi
                        }
                    ]
                },
                {
                    name: "Abóbora Butternut Squash",
                    image: aboboraButternut,
                    describe: `
                    <b>A Abóbora Butternut</b> é da espécie Cucurbita moschata. Os frutos atingem de 6 cm a 10 cm de diâmetro e 20 a 25 cm de comprimento. Pesam em torno de 1kg. <b>A abóbora é quase só polpa e possui pouca semente</b>.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> lisa e com cor de avelã clara<br/>
                    <b>Polpa:</b> laranja e ligeiramente açucarada<br/>
                    <b>Formato:</b> cilíndrico com pescoço de cor creme<br/>
                    <b>Vitaminas:</b> A e C, rica em antioxidantes e com alto teor de fibras.`,
                    attachments: [
                        {
                            name: 'Abóbora Butternut Squash',
                            file: fichaAboboraButternut
                        }
                    ]
                },
                {
                    name: "Abóbora Sergipana",
                    image: aboboraSergipana,
                    describe: `
                    A <b>Abóbora Sergipana</b>, também é conhecida como <b>Abóbora Jerimum de leite</b> ou Maranhão é cultivada principalmente no Norte e Nordeste do Brasil. Seu nome científico é Cucurbita moschata. A abóbora é o fruto da aboboreira, uma planta rasteira da mesma família da melancia e do pepino.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada<br/>
                    <b>Polpa:</b> de cor laranja acentuado, com alta textura e sabor<br/>
                    <b>Formato:</b> globular achatados com gomos característicos <br/>
                    <b>Vitaminas:</b> A, C, E, B1, B3 e B5`,
                    attachments: [
                        {
                            name: 'Abóbora Sergipana',
                            file: fichaAboboraSergipana
                        }
                    ]
                },
                {
                    name: "Banana Nanica",
                    image: banana,
                    describe: `
                    Originada na Ásia, somente foi conhecida na Europa e nos Estados Unidos no século 17. Típica fruta tropical, sua produção atual ocorre em grande parte dos países <b>Americanos,
                    Africanos e Asiáticos</b>. É uma das frutas mais importantes tanto em volume de produção, como em valor alimentar; além disso, é <b>comercializada o ano inteiro</b>.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada<br/>
                    <b>Polpa:</b> macia e doce<br/>
                    <b>Sementes:</b> Não possui sementes<br/>
                    <b>Vitaminas:</b> rica em fibras, potássio, vitaminas C e A`,
                    attachments: [
                        {
                            name: 'Banana Nanica',
                            file: fichaBanana
                        }
                    ]
                },
                {
                    name: "Batata Doce Aarapey ou Uruguaia",
                    image: batata_doceUruguaia,
                    describe: `
                    A <b>batata doce</b> é um vegetal rústico classificado como uma raiz tuberosa. Pode ser cultivada tanto em regiões de clima seco quanto de clima temperado. O ciclo médio dessa batata é 150 a 180 dias, podendo ser considerado precoce (120 dias) quando plantado nos meses mais quentes: Setembro a Fevereiro.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> possui uma película externa roxa e uma segunda película mais fina amarela<br/>
                    <b>Polpa:</b> de cor amarela e mais seca<br/>
                    <b>Semente:</b>  irregular e compridas<br/>
                    <b>Sabor:</b>  menos doce<br/>
                    <b>Vitaminas:</b> do complexo B e C, rica em carboidratos e fibras alimentares`,
                    attachments: [
                        {
                            name: 'Batata Doce Aarapey',
                            file: fichaBatataDoceArapey
                        }
                    ]
                },
                {
                    name: "Batata Doce",
                    image: batataDoce,
                    describe: `
                    A <b>batata doce</b> é um vegetal rústico classificado como uma raiz tuberosa. Pode ser cultivada tanto em regiões de clima seco quanto de clima temperado. O ciclo desta batata é considerado médio (150 a 180 dias) quando o plantio ocorre de Setembro a Fevereiro e longo (180 a 220 dias) nas demais épocas do ano.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> de cor roxa<br/>
                    <b>Polpa:</b> de cor branca<br/>
                    <b>Semente:</b>  alongado e uniforme<br/>
                    <b>Sabor:</b>  adocicado<br/>
                    <b>Vitaminas:</b> do complexo B e C, rica em carboidratos e fibras alimentares`,
                    attachments: [
                        {
                            name: 'Batata Doce',
                            file: fichaBatataDoceCanadense
                        }
                    ]
                },
                {
                    name: "Limão Siciliano",
                    image: limao2,
                    describe: `
                    O <b>limão-siciliano</b> (espécie Citrus x limon) é o fruto de uma pequena árvore de folha perene originária da região sudeste da Ásia, da família das rutáceas. O limão-siciliano cresce em muitas regiões quentes do mundo, como: <b>Índia, Argentina, Espanha, Irã e Estados Unidos (nos estados da Califórnia e do Arizona) são os maiores produtores</b>. Contém muitos compostos vegetais, minerais e óleos essenciais.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada e grossa<br/>
                    <b>Polpa:</b> ácido suave<br/>
                    <b>Semente:</b> Possui sementes<br/>
                    <b>Vitaminas:</b> rica em vitaminas C, A e do complexo B<br/>
                    <b>Minerais:</b> Fonte de fósforo, ferro, magnésio e potássio.`,
                    attachments: [
                        {
                            name: 'Limão Siciliano',
                            file: fichaLimaoSiciliano
                        }
                    ]
                },
                {
                    name: "Limão Tahiti",
                    image: limao,
                    describe: `
                    O <b>limão Tahiti</b> é um fruto de origem tropical e no contexto mundial, os principais produtores
                    são o México, EUA (Flórida), Egito, Índia, Peru e Brasil.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> lisa ou ligeiramente rugosa, de coloração verde<br/>
                    <b>Polpa:</b> esbranquiçada, muito suculenta e de qualidade menos ácida<br/>
                    <b>Formato:</b> arredondado<br/>
                    <b>Sementes:</b> sem sementes Vitaminas: C, B1 e B2.`,
                    attachments: [
                        {
                            name: 'Limão Tahiti',
                            file: fichaLimaoTahiti
                        }
                    ]
                },
                {
                    name: "Pera",
                    image: pera,
                    describe: `
                    A <b>pera</b> é o fruto comestível da pereira, uma árvore do gênero Pyrus L., pertencente à família Rosaceae, e que conta com mais de três mil variedades de espécies cultivadas em todo o mundo.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> pode variar entre verde, amarela e dourada de acordo com o seu amadurecimento<br/>
                    <b>Polpa:</b> cor branca, macia-fundente, granulosa, doce, não ácida e sumarenta<br/>
                    <b>Vitaminas:</b> rica em magnésio, fósforo, selênio, fibras, vitaminas A, C e do complexo B<br/>
                    <b>Formato:</b> oval ou arredondado.`,
                    attachments: [
                        {
                            name: 'Pera',
                            file: fichaPera
                        }
                    ]
                },
                {
                    name: "Melancia",
                    image: melancia,
                    describe: `
                    A <b>melancieira é nativa da África</b>. Foi introduzida há muito no Brasil e se adaptou bem, tornando-se uma das frutas mais importantes do mercado, apesar de ser cultivada como uma hortaliça. O seu fruto é uma baga esférica, grande, com muitas sementes negras, também comestíveis, quando torradas.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> lisa na cor escura e clara (listras)<br/>
                    <b>Polpa:</b> vermelha e apresenta várias sementes pretas e pequenas na parte interna<br/>
                    <b>Formato:</b> arredondado (oval)`,
                    attachments: [
                        {
                            name: 'Melancia',
                            file: fichaMelancia
                        }
                    ]
                },
                {
                    name: "Maçã",
                    image: maca,
                    describe: `
                    A <b>Maçã</b> é uma das frutas mais antigas conhecidas, de origem caucasiana, na Ásia, ela é típica dos ambientes de clima temperado. De um paladar extremamente saboroso, ela é também detentora de um alto poder de nutrição.<br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> vermelha<br/>
                    <b>Polpa:</b> branca com poucas sementes pequenas<br/>
                    <b>Vitaminas:</b> C, E, B1 e B2.`,
                    attachments: [
                        {
                            name: 'Maçã',
                            file: fichaMaca
                        }
                    ]
                },
                {
                    name: "Manga Ataulfo",
                    image: mangaAtaulfo,
                    describe: `
                    A <b>manga Ataulfo</b>, também conhecida como manga de Mel, Manila, Amarelo, Bebê e
                    Champanhe é cultivada em áreas tropicais em todo o mundo, mas é original do México.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelo dourado<br/>
                    <b>Polpa:</b> macia e firme, sem fibras<br/>
                    <b>Formato:</b> oval e plana<br/>
                    <b>Semente:</b> muito pequena.`,
                    attachments: [
                        {
                            name: 'Manga Ataulfo',
                            file: fichaMangaAtaulfo
                        }
                    ]
                },
                {
                    name: "Manga Kent",
                    image: mangaKent,
                    describe: `
                    A <b>Manga Kent</b> é uma cultivar de origem americana no ano de 1945, na Flórida. Derivada da Manga Brooks. Foi introduzida no Brasil na década de 60. Assim como a Palmer é de ciclo semi tardio.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> verde amarelado, corado de vermelho purpúreo<br/>
                    <b>Polpa:</b> polpa cremosa, sem fibras e muito doce<br/>
                    <b>Formato:</b> oval<br/>
                    <b>Minerais:</b> Cálcio, Zinco, Potássio, Ferro e Magnésio<br/>
                    <b>Vitaminas:</b> A, B, C e E.`,
                    attachments: [
                        {
                            name: 'Manga Kent',
                            file: fichaMangaKent
                        }
                    ]
                },
                {
                    name: "Manga Keitt",
                    image: mangaKeitt,
                    describe: `
                    A árvore provavelmente originária do noroeste da Índia e do norte da Birmânia e das ladeiras do Himalaia é uma espécie subtropical e tropical, sendo cultivada em algumas regiões de clima mediterrânico.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> na cor verde amarelado, corado de vermelho-róseo e com uma pele grossa<br/>
                    <b>Polpa:</b> cor amarela, alaranjada e suculenta, sem fibra<br/>
                    <b>Formato:</b> oval com ápice ligeiramente oblíquo<br/>
                    <b>Vitaminas:</b> A, B, C e E.`,
                    attachments: [
                        {
                            name: 'Manga Keitt',
                            file: fichaMangaKeitt
                        }
                    ]
                },
                {
                    name: "Manga Palmer",
                    image: mangaPalmer,
                    describe: `
                    A <b>Manga Palmer</b> tem origem Asiática, no entanto, os climas tropicais e subtropicais do Brasil permitem que ela tenha um bom desenvolvimento e produção. A árvore de nome científico Mangifera indica pertence à família das Anacardiaceae, a qual compreende cerca de 800 espécies, a mangueira, por sua vez, pode atingir 45 metros de altura.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> fina que podem variar entre gradientes de cores amarelas, rosas, vermelhas e verde<br/>
                    <b>Polpa:</b> amarelada, firme e com pouca ou nenhuma fibra<br/>
                    <b>Formato:</b> ovóide-oblongo, mais ou menos alongado, de acordo com a variedade<br/>
                    <b>Vitaminas:</b> A, C, B1, B2, B5 e sais como o fósforo, ferro e cálcio<br/>
                    <b>Sabor:</b> Doce.`,
                    attachments: [
                        {
                            name: 'Manga Palmer',
                            file: fichaMangaPalmer
                        }
                    ]
                },
                {
                    name: "Manga Tommy",
                    image: manga,
                    describe: `
                    Originária do sudoeste asiático, a mangueira (Mangifera indica L.) disseminou-se para várias regiões do mundo. Trata-se de uma fruta tropical de grande aceitação pelos consumidores em virtude de suas características exóticas e da composição nutricional. <b>A manga Tommy é uma das principais variedades cultivadas no Brasil em áreas comerciais</b>.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> espessa e de cor vermelho-púrpura intensa<br/>
                    <b>Polpa:</b> firme, suculenta, com teor médio de fibra, tem coloração laranja-amarela<br/>
                    <b>Formato:</b> oval<br/>
                    <b>Vitaminas:</b> A e C, possui ainda Niacina e Tiamina.`,
                    attachments: [
                        {
                            name: 'Manga Tommy',
                            file: fichaMangaTommy
                        }
                    ]
                },
                {
                    name: "Melão Amarelo",
                    image: melaoAmarelo,
                    describe: `
                    O <b>melão amarelo</b>, originário da Ásia e aclimatado no Brasil, é a fruta do meloeiro, planta rasteira e herbácea, da família das Cucurbitáceas.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> enrugado, amarelo intenso<br/>
                    <b>Formato:</b> arredondado<br/>
                    <b>Polpa:</b> suculenta, branca e levemente esverdeada.`,
                    attachments: [
                        {
                            name: 'Melão Amarelo',
                            file: fichaMelaoAmarelo
                        }
                    ]
                },
                {
                    name: "Melão Pele de Sapo",
                    image: melaoSapo,
                    describe: `
                    Pertence à família Cucurbitácea, com características morfológicas de plantas herbáceas, cujo principal órgão utilizado é o <b>fruto</b>, o qual se constitui de uma baga geralmente grande cujas paredes externas endurecem e as internas permanecem carnosas.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> na cor verde escura e textura levemente rugosa<br/>
                    <b>Formato:</b> alongado<br/>
                    <b>Polpa:</b> firme e na cor branca.`,
                    attachments: [
                        {
                            name: 'Melão Pele de Sapo',
                            file: fichaMelaoSapo
                        }
                    ]
                },
            ],
            [
                {
                    name: "Polpa Integral de Goiaba Orgânica",
                    image: goiaba,
                    describe: `
                    <b>Polpa Integral de Goiaba Orgânica</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade interna da Sucus Brasil e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>BrixMínimo</b> -7,00 | ºB | MA CQ 001<br/>
                    <b>Acidez Mínimo</b> - 0,40 | % | MA CQ 002<br/>
                    <b>pH Mínimo</b> - 3,5 | MA CQ 004`,
                    attachments: [
                        {
                            name: 'Polpa Integral de Goiaba Orgânica',
                            file: fichaGoiabaOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Abacaxi Orgânico",
                    image: abacaxi,
                    describe: `
                    <b>Polpa de Abacaxi Orgânico</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> -12,00–14,00 | °B | MA CQ 001<br/>
                    <b>Acidez</b> -0,50–0,90 | % | MA CQ 002<br/>
                    <b>Ratio</b> - 13,30 – 28,00 | <br/>
                    <b>Brix/Acidez</b> - MA CQ 003 <br/>
                    <b>pH</b> < 4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 30 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Polpa de Abacaxi Orgânico',
                            file: fichAbacaxiOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Acerola Integral e Orgânica",
                    image: acerola,
                    describe: `
                    <b>Polpa de Acerola Integral</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 6,00–8,00 | ºB | MA CQ 001 <br/>
                    <b>Acidez</b> 1,00–1,60 | % | MA CQ 002 <br/>
                    <b>Ratio</b> 4,50 – 7,50 | <br/>
                    <b>Brix/Acidez</b> MA CQ 003 <br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 30 | % | MA CQ 005<br/>
                    <b>Vitamina C</b> 1.100 Mg/100G`,
                    attachments: [
                        {
                            name: 'Polpa de Acerola Integral',
                            file: fichaAcerolaInt
                        },
                        {
                            name: 'Polpa de Acerola Orgância',
                            file: fichaAcerolaOrg
                        }
                    ]
                },
                {
                    name: "Suco de Limão Concentrado",
                    image: limao,
                    describe: `
                    <b>Suco de Limão Concentrado Congelado</b> obtido da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 50,0–55,0 | ºB | MA CQ 001 <br/>
                    <b>Vitamina C</b> 300 – 400 g/100 mL TRITATION <br/>
                    <b>pH</b> - 2,0 – 3,5 | MA CQ 004<br/>
                    <b>GLP</b> (°) 400 - 415 Acid X 10 X Densidad MA CQ 003 <br/>
                    <b>Essential Oil</b> (%v/v) Mín. 0,015 (%v/v) MA CQ 002 <br/>
                    <b>Pulp</b> 11,0-12,0 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Limão Concentrado',
                            file: fichaLimaoConcentrado
                        }
                    ]
                },
                {
                    name: "Polpa de Manga",
                    image: manga,
                    describe: `
                    <b>Polpa de Manga</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 12,00–14,00 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> 0,30-0,70 | % | MA CQ 002<br/>
                    <b>Ratio</b>17,00 - 46,70 Brix/Acidez MA CQ 003<br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 60 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Purê de manga convencional',
                            file: fichaMangaTommyInt
                        },
                        {
                            name: 'Purê de manga orgânico',
                            file: fichaMangaOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Maracujá Integral e Orgânico",
                    image: maracuja,
                    describe: `
                    <b>Polpa de Maracujá Integral</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade interna da Sucus Brasil legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 12,00 – 14,00 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> 2,80 – 4,00 | % | MA CQ 002<br/>
                    <b>Ratio</b> 3,00 - 5,00 Brix/Acidez MA CQ 003<br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 25 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Polpa de Maracujá Integral',
                            file: fichaMaracujaInt
                        },
                        {
                            name: 'Polpa de Maracujá Orgânico',
                            file: fichaMaracujaOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Mamão Integral e Orgânico",
                    image: mamao,
                    describe: `
                    <b>Polpa de Mamão Integral</b> obtida da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 10 - 14 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> (%) | 100g 0,40 – 0,70 (%) | 100g MA CQ 002<br/>
                    <b>Ratio</b> 14 – 35 brix/acidity g/100g citric acid MA CQ 003<br/>
                    <b>pH</b> 3,0 – 4,0 | MA CQ 004<br/>
                    <b>Polpa</b> 80 -98 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Polpa de Mamão Integral',
                            file: fichaMamaoInt
                        },
                        {
                            name: 'Polpa de Mamão Orgânico',
                            file: fichaMamaoOrg
                        }
                    ]
                },
                {
                    name: "Suco de Melancia Orgânica Congelada",
                    image: melancia,
                    describe: `
                    <b>Suco de Melancia Orgânica Congelada</b> obtida da parte comestível da mesma fruta sendo selecionada, processada sob condições adequadas de higiene, atendendo aos padrões de qualidade internos da Sucus Brasil.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 6,00 - 8,00 | °B | MA CQ 001<br/>
                    <b>Acidez</b> 0,20 – 0,30 | % | MA CQ 002<br/>
                    <b>pH</b> < 5,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 10 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Melancia Orgânica Congelada',
                            file: fichaMelanciaOrg
                        },
                    ]
                },
                {
                    name: "Purê de Manga Convencional e Orgânico",
                    image: manga,
                    describe: `
                    <b>Polpa de Manga</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 12,00–14,00 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> 0,30-0,70 | % | MA CQ 002<br/>
                    <b>Ratio</b>17,00 - 46,70 Brix/Acidez MA CQ 003<br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 60 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Purê de Manga Tommy',
                            file: fichaMangaPure1
                        },
                        {
                            name: 'Purê de Manga Palmer',
                            file: fichaMangaPure2
                        },
                    ]
                },
                {
                    name: "Suco de Laranja Concentrado",
                    image: laranja,
                    describe: `
                    <b>O Suco de Laranja Concentrado Congelado</b>, produto não fermentado obtido da parte comestível da fruta fresca e madura de Citrus sinensis L. Osbeck, é preparado por processos adequados, que mantêm as características físicas, químicas, organolépticas e nutricionais essenciais da fruta de onde provém. O suco é produzido de acordo com as Boas Práticas de Fabricação.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 65,5 – 66,5 | ºB | IFU no 08<br/>
                    <b>Ratio</b> 9,0-20,0 brix/acidez em g/100g de ácido cítrico anidro IFU no 03/ USDA<br/>
                    <b>Óleo Essencial</b> 0,006- 0,010 %v/v IFU no 45<br/>
                    <b>Polpa Min.</b> 8 -12 | % | IFU no 60`,
                    attachments: [
                        {
                            name: 'Suco de Laranja Concentrado',
                            file: fichaLaranjaConcentrado
                        },
                    ]
                },
                {
                    name: "Suco de Abacaxi Concentrado Congelado",
                    image: abacaxi,
                    describe: `
                    <b>Suco de Abacaxi Concentrado</b> a 60°Brix obtido da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 59,50- 60,50 | ºB | MA CQ 001<br/>
                    <b>ph</b> 3,00 – 4,20 | MA CQ 004<br/>
                    <b>Ratio</b> 15,00 – 35,00 brix/acidity g/100g citric acid MA CQ 003<br/>
                    <b>Acidez</b> (%) | 100g 1,70 – 4,00 (%) | 100g MA CQ 002<br/>
                    <b>Aditivos</b> Ácido ascórbico Max. 21,5 mg/ 100g MA CQ 002<br/>
                    <b>Polpa</b> 8,00 - 20,00 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Abacaxi Concentrado Congelado',
                            file: fichaAbacaxiConcentrado
                        },
                    ]
                },
                {
                    name: "Suco de Limão Congelado",
                    image: limao,
                    describe: `
                    <b>Suco de Limão Congelado</b>obtido da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.<br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 5,0 – 13,0 | ºB | MA CQ 001<br/>
                    <b>Vitamin C</b> 15,0 – 70,0 g/100 mL TRITATION<br/>
                    <b>pH</b> 2,0–3,5| MA CQ 004<br/>
                    <b>GLP(º)</b> 15,00-35,00 | Acid X10XDensidad | MA CQ 003<br/>
                    <b>Essential Oil</b> (%v/v) Mín. 0,015 (%v/v) MA CQ 002<br/>
                    <b>Polpa</b> 6,0 - 30,0 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Limão Congelado',
                            file: fichaSucoLimao
                        },
                    ]
                },
            ],
        ]
    },
    es: {
        //Menu
        titleMenu: 'Menú',
        closeMenu: 'Cerrar el menú',
        menuHome: 'Home',
        menuAbout: 'Sobre',
        menuProducts: 'Productos',
        menuContact: 'Contacto',

        //Home
        titleBanner: '¡La mejor fruta del mundo!',
        sloganHome: 'Trabajamos con Fruta Fresca y Pulpa de Fruta',
        subSloganHome: 'In Natura, Jugo ou Pulpa',
        letterHome: '¡La mejor fruta del mundo!',

        //Sobre
        titleAbout: 'Sobre',
        descAbout: 'Somos una empresa fundada en 2014, activa en la industria y comercialización de jugos preparados, pulpa de frutas, frutas frescas y derivados.',
        desc2About: 'Tenemos nuestra sede administrativa en la ciudad de Juazeiro do Norte - CE, donde se gestionan los recursos humanos, administrativos y técnicos.',
        missionTitle: 'Misión',
        missionDesc: 'Servir al mercado consumidor nacional y extranjero de jugos frescos, pulpas y frutas con productos de calidad, esforzándonos por la total satisfacción de nuestros clientes.',
        visionTitle: 'Visão',
        visionDesc: 'Ser líder y referente en la producción, comercialización y distribución de jugos frescos, pulpas y frutas.',
        valuesTitle: 'Valores',
        valuesDesc: 'Responsabilidad, Calidad y Confianza.',
        titleWhereAbout: 'Donde estamos',

        //Produtos
        titleProducts: 'Nuestros productos',
        box1Products: 'In Natura',
        box2Products: 'Jugo/Polpa',

        //Contato
        titleContact: 'Contacto',
        textContact: 'Elija cómo prefiere contactarnos, estamos listos para atenderlo.',
        titleFAQ: 'Preguntas Frecuentes',
        questionsFAQ: [
            {
                question: '¿Dónde está la sede administrativa de Sucus?',
                response: 'Nuestra sede se encuentra en la ciudad de <b>Juazeiro do Norte-CE</b>.',
            },
            {
                question: '¿A qué países sirve?',
                response: 'Atendemos los mercados <b>europeo, norteamericano y sudamericano</b>.',
            },
            {
                question: '¿Trabajas con fruta fresca?',
                response: 'Sí, trabajamos con <b>exportaciones, importaciones y mercado interno</b>.',
            },
            {
                question: '¿Con qué frutas trabajas?',
                response: 'Trabajamos con las siguientes <b> frutas frescas </b>: Piña, Calabaza, Calabaza Sergipana, Banana Nanica, Batata Arapey, Batata Canadiense, Limón Siciliano, Limón Tahití, Manzana, Mango Ataulfo, Mango Kent, Mango Palmer, Tommy Mango, Sandía, Yellow Melon, Pele De Sapo Melon y Pera. <br/><br/> <b> Jugos y Pulpa </b> trabajamos con: Piña Entera, Piña Orgánica, Acerola Orgánica, Acerola Convencional, Guayaba Orgánica, Guayaba Convencional, Naranja Concentrada, Naranja Entera, Limón Concentrado, Limón Integral, Mango orgánico, mango convencional, papaya orgánica, papaya convencional, maracuyá convencional y maracuyá orgánica.',
            }
        ],

        //Proccess
        titleProccess: 'Proceso',
        descProccess: 'Seguimos todas las etapas del proceso de producción, desde la cosecha de la fruta hasta el producto final. De esta forma ofrecemos un producto con el más alto estándar de calidad para nuestros clientes.',
        titleFruits: 'Frutas seleccionadas',
        descFruits: 'Nuestras frutas son seleccionadas y tratadas, con altas condiciones de higiene y sanitización, cumpliendo con las normas higiénicas y sanitarias, basadas en las normas y legislaciones internacionales en materia de seguridad alimentaria y aseguramiento de la calidad.',
        titleQuality: 'Producto de calidad',
        descQuality: 'Nuestros empleados y proveedores de materias primas, insumos y servicios son conscientes del compromiso de la empresa de brindar una garantía total de calidad a nuestro producto final.',
        sloganProccess: 'La ejecución de cada una de las operaciones se realiza con un enfoque en el cumplimiento de los requisitos para la <b> EXPORTACIÓN Y MERCADO INTERNO </b>',

        //Sazonalidade
        titleSazonality: 'Estacionalidad',
        btnMore: 'Sepa mas',
        months: [
            { value: 0, label: 'Enero' },
            { value: 1, label: 'Febrero' },
            { value: 2, label: 'Marzo' },
            { value: 3, label: 'Abril' },
            { value: 4, label: 'Mayo' },
            { value: 5, label: 'Junio' },
            { value: 6, label: 'Julio' },
            { value: 7, label: 'Agosto' },
            { value: 8, label: 'Septiembre' },
            { value: 9, label: 'Octubre' },
            { value: 10, label: 'Noviembre' },
            { value: 11, label: 'Diciembre' }
        ],

        //Rodapé
        titleCertifieds: 'Certificaciones',
        titleWhere: 'Donde estamos',
        titleIACC: 'Dona a la IACC',
        descIACC: 'Instituto de Apoyo para Niños con Cáncer',
        btnIACC: 'Donar',
        titleSiteMap: 'Mapa del sitio',
        textCopy: 'Copyright © 2021 Sucus Brasil | Todos los derechos reservados',




        //FRUTAS
        titleBtnFruits: 'Ficha(s) Técnica(s)',
        fruits: [
            [
                {
                    name: "Abacaxi",
                    image: abacaxi,
                    describe: `
                    <b>O Ananás ou abacaxi (Ananas comosus)</b> é uma infrutescência tropical produzida pela planta de mesmo nome. O abacaxi é uma fruta típica de países tropicais e subtropicais, portanto, não se adapta em regiões de clima frio, costuma florescer na época do inverno mas é ofertado no mercado durante o ano todo, em razão de ser plantado em diferentes locais, com diferentes técnicas de cultivo, incluindo diversos tipos de mudas e controle químico do florescimento. Os principais países produtores de abacaxi, segundo a Organização das Nações Unidas para a Alimentação e Agricultura (2008), são <b>o Brasil, a Tailândia, as Filipinas, a Costa Rica, a República Popular da China, a Índia e a Indonésia.</b>
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada e grossa<br/>
                    <b>Polpa:</b> levemente ácida<br/>
                    <b>Semente:</b> Não possui sementes<br/>
                    <b>Vitaminas:</b> rica em vitamina C, B1 e A.`,
                    attachments: [
                        {
                            name: 'Abacaxi',
                            file: fichaAbacaxi
                        }
                    ]
                },
                {
                    name: "Abóbora Butternut Squash",
                    image: aboboraButternut,
                    describe: `
                    <b>A Abóbora Butternut</b> é da espécie Cucurbita moschata. Os frutos atingem de 6 cm a 10 cm de diâmetro e 20 a 25 cm de comprimento. Pesam em torno de 1kg. <b>A abóbora é quase só polpa e possui pouca semente</b>.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> lisa e com cor de avelã clara<br/>
                    <b>Polpa:</b> laranja e ligeiramente açucarada<br/>
                    <b>Formato:</b> cilíndrico com pescoço de cor creme<br/>
                    <b>Vitaminas:</b> A e C, rica em antioxidantes e com alto teor de fibras.`,
                    attachments: [
                        {
                            name: 'Abóbora Butternut Squash',
                            file: fichaAboboraButternut
                        }
                    ]
                },
                {
                    name: "Abóbora Sergipana",
                    image: aboboraSergipana,
                    describe: `
                    A <b>Abóbora Sergipana</b>, também é conhecida como <b>Abóbora Jerimum de leite</b> ou Maranhão é cultivada principalmente no Norte e Nordeste do Brasil. Seu nome científico é Cucurbita moschata. A abóbora é o fruto da aboboreira, uma planta rasteira da mesma família da melancia e do pepino.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada<br/>
                    <b>Polpa:</b> de cor laranja acentuado, com alta textura e sabor<br/>
                    <b>Formato:</b> globular achatados com gomos característicos <br/>
                    <b>Vitaminas:</b> A, C, E, B1, B3 e B5`,
                    attachments: [
                        {
                            name: 'Abóbora Sergipana',
                            file: fichaAboboraSergipana
                        }
                    ]
                },
                {
                    name: "Banana Nanica",
                    image: banana,
                    describe: `
                    Originada na Ásia, somente foi conhecida na Europa e nos Estados Unidos no século 17. Típica fruta tropical, sua produção atual ocorre em grande parte dos países <b>Americanos,
                    Africanos e Asiáticos</b>. É uma das frutas mais importantes tanto em volume de produção, como em valor alimentar; além disso, é <b>comercializada o ano inteiro</b>.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada<br/>
                    <b>Polpa:</b> macia e doce<br/>
                    <b>Sementes:</b> Não possui sementes<br/>
                    <b>Vitaminas:</b> rica em fibras, potássio, vitaminas C e A`,
                    attachments: [
                        {
                            name: 'Banana Nanica',
                            file: fichaBanana
                        }
                    ]
                },
                {
                    name: "Batata Doce Aarapey ou Uruguaia",
                    image: batata_doceUruguaia,
                    describe: `
                    A <b>batata doce</b> é um vegetal rústico classificado como uma raiz tuberosa. Pode ser cultivada tanto em regiões de clima seco quanto de clima temperado. O ciclo médio dessa batata é 150 a 180 dias, podendo ser considerado precoce (120 dias) quando plantado nos meses mais quentes: Setembro a Fevereiro.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> possui uma película externa roxa e uma segunda película mais fina amarela<br/>
                    <b>Polpa:</b> de cor amarela e mais seca<br/>
                    <b>Semente:</b>  irregular e compridas<br/>
                    <b>Sabor:</b>  menos doce<br/>
                    <b>Vitaminas:</b> do complexo B e C, rica em carboidratos e fibras alimentares`,
                    attachments: [
                        {
                            name: 'Batata Doce Aarapey',
                            file: fichaBatataDoceArapey
                        }
                    ]
                },
                {
                    name: "Batata Doce",
                    image: batataDoce,
                    describe: `
                    A <b>batata doce</b> é um vegetal rústico classificado como uma raiz tuberosa. Pode ser cultivada tanto em regiões de clima seco quanto de clima temperado. O ciclo desta batata é considerado médio (150 a 180 dias) quando o plantio ocorre de Setembro a Fevereiro e longo (180 a 220 dias) nas demais épocas do ano.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> de cor roxa<br/>
                    <b>Polpa:</b> de cor branca<br/>
                    <b>Semente:</b>  alongado e uniforme<br/>
                    <b>Sabor:</b>  adocicado<br/>
                    <b>Vitaminas:</b> do complexo B e C, rica em carboidratos e fibras alimentares`,
                    attachments: [
                        {
                            name: 'Batata Doce',
                            file: fichaBatataDoceCanadense
                        }
                    ]
                },
                {
                    name: "Limão Siciliano",
                    image: limao2,
                    describe: `
                    O <b>limão-siciliano</b> (espécie Citrus x limon) é o fruto de uma pequena árvore de folha perene originária da região sudeste da Ásia, da família das rutáceas. O limão-siciliano cresce em muitas regiões quentes do mundo, como: <b>Índia, Argentina, Espanha, Irã e Estados Unidos (nos estados da Califórnia e do Arizona) são os maiores produtores</b>. Contém muitos compostos vegetais, minerais e óleos essenciais.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada e grossa<br/>
                    <b>Polpa:</b> ácido suave<br/>
                    <b>Semente:</b> Possui sementes<br/>
                    <b>Vitaminas:</b> rica em vitaminas C, A e do complexo B<br/>
                    <b>Minerais:</b> Fonte de fósforo, ferro, magnésio e potássio.`,
                    attachments: [
                        {
                            name: 'Limão Siciliano',
                            file: fichaLimaoSiciliano
                        }
                    ]
                },
                {
                    name: "Limão Tahiti",
                    image: limao,
                    describe: `
                    O <b>limão Tahiti</b> é um fruto de origem tropical e no contexto mundial, os principais produtores
                    são o México, EUA (Flórida), Egito, Índia, Peru e Brasil.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> lisa ou ligeiramente rugosa, de coloração verde<br/>
                    <b>Polpa:</b> esbranquiçada, muito suculenta e de qualidade menos ácida<br/>
                    <b>Formato:</b> arredondado<br/>
                    <b>Sementes:</b> sem sementes Vitaminas: C, B1 e B2.`,
                    attachments: [
                        {
                            name: 'Limão Tahiti',
                            file: fichaLimaoTahiti
                        }
                    ]
                },
                {
                    name: "Pera",
                    image: pera,
                    describe: `
                    A <b>pera</b> é o fruto comestível da pereira, uma árvore do gênero Pyrus L., pertencente à família Rosaceae, e que conta com mais de três mil variedades de espécies cultivadas em todo o mundo.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> pode variar entre verde, amarela e dourada de acordo com o seu amadurecimento<br/>
                    <b>Polpa:</b> cor branca, macia-fundente, granulosa, doce, não ácida e sumarenta<br/>
                    <b>Vitaminas:</b> rica em magnésio, fósforo, selênio, fibras, vitaminas A, C e do complexo B<br/>
                    <b>Formato:</b> oval ou arredondado.`,
                    attachments: [
                        {
                            name: 'Pera',
                            file: fichaPera
                        }
                    ]
                },
                {
                    name: "Melancia",
                    image: melancia,
                    describe: `
                    A <b>melancieira é nativa da África</b>. Foi introduzida há muito no Brasil e se adaptou bem, tornando-se uma das frutas mais importantes do mercado, apesar de ser cultivada como uma hortaliça. O seu fruto é uma baga esférica, grande, com muitas sementes negras, também comestíveis, quando torradas.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> lisa na cor escura e clara (listras)<br/>
                    <b>Polpa:</b> vermelha e apresenta várias sementes pretas e pequenas na parte interna<br/>
                    <b>Formato:</b> arredondado (oval)`,
                    attachments: [
                        {
                            name: 'Melancia',
                            file: fichaMelancia
                        }
                    ]
                },
                {
                    name: "Maçã",
                    image: maca,
                    describe: `
                    A <b>Maçã</b> é uma das frutas mais antigas conhecidas, de origem caucasiana, na Ásia, ela é típica dos ambientes de clima temperado. De um paladar extremamente saboroso, ela é também detentora de um alto poder de nutrição.<br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> vermelha<br/>
                    <b>Polpa:</b> branca com poucas sementes pequenas<br/>
                    <b>Vitaminas:</b> C, E, B1 e B2.`,
                    attachments: [
                        {
                            name: 'Maçã',
                            file: fichaMaca
                        }
                    ]
                },
                {
                    name: "Manga Ataulfo",
                    image: mangaAtaulfo,
                    describe: `
                    A <b>manga Ataulfo</b>, também conhecida como manga de Mel, Manila, Amarelo, Bebê e
                    Champanhe é cultivada em áreas tropicais em todo o mundo, mas é original do México.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelo dourado<br/>
                    <b>Polpa:</b> macia e firme, sem fibras<br/>
                    <b>Formato:</b> oval e plana<br/>
                    <b>Semente:</b> muito pequena.`,
                    attachments: [
                        {
                            name: 'Manga Ataulfo',
                            file: fichaMangaAtaulfo
                        }
                    ]
                },
                {
                    name: "Manga Kent",
                    image: mangaKent,
                    describe: `
                    A <b>Manga Kent</b> é uma cultivar de origem americana no ano de 1945, na Flórida. Derivada da Manga Brooks. Foi introduzida no Brasil na década de 60. Assim como a Palmer é de ciclo semi tardio.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> verde amarelado, corado de vermelho purpúreo<br/>
                    <b>Polpa:</b> polpa cremosa, sem fibras e muito doce<br/>
                    <b>Formato:</b> oval<br/>
                    <b>Minerais:</b> Cálcio, Zinco, Potássio, Ferro e Magnésio<br/>
                    <b>Vitaminas:</b> A, B, C e E.`,
                    attachments: [
                        {
                            name: 'Manga Kent',
                            file: fichaMangaKent
                        }
                    ]
                },
                {
                    name: "Manga Keitt",
                    image: mangaKeitt,
                    describe: `
                    A árvore provavelmente originária do noroeste da Índia e do norte da Birmânia e das ladeiras do Himalaia é uma espécie subtropical e tropical, sendo cultivada em algumas regiões de clima mediterrânico.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> na cor verde amarelado, corado de vermelho-róseo e com uma pele grossa<br/>
                    <b>Polpa:</b> cor amarela, alaranjada e suculenta, sem fibra<br/>
                    <b>Formato:</b> oval com ápice ligeiramente oblíquo<br/>
                    <b>Vitaminas:</b> A, B, C e E.`,
                    attachments: [
                        {
                            name: 'Manga Keitt',
                            file: fichaMangaKeitt
                        }
                    ]
                },
                {
                    name: "Manga Palmer",
                    image: mangaPalmer,
                    describe: `
                    A <b>Manga Palmer</b> tem origem Asiática, no entanto, os climas tropicais e subtropicais do Brasil permitem que ela tenha um bom desenvolvimento e produção. A árvore de nome científico Mangifera indica pertence à família das Anacardiaceae, a qual compreende cerca de 800 espécies, a mangueira, por sua vez, pode atingir 45 metros de altura.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> fina que podem variar entre gradientes de cores amarelas, rosas, vermelhas e verde<br/>
                    <b>Polpa:</b> amarelada, firme e com pouca ou nenhuma fibra<br/>
                    <b>Formato:</b> ovóide-oblongo, mais ou menos alongado, de acordo com a variedade<br/>
                    <b>Vitaminas:</b> A, C, B1, B2, B5 e sais como o fósforo, ferro e cálcio<br/>
                    <b>Sabor:</b> Doce.`,
                    attachments: [
                        {
                            name: 'Manga Palmer',
                            file: fichaMangaPalmer
                        }
                    ]
                },
                {
                    name: "Manga Tommy",
                    image: manga,
                    describe: `
                    Originária do sudoeste asiático, a mangueira (Mangifera indica L.) disseminou-se para várias regiões do mundo. Trata-se de uma fruta tropical de grande aceitação pelos consumidores em virtude de suas características exóticas e da composição nutricional. <b>A manga Tommy é uma das principais variedades cultivadas no Brasil em áreas comerciais</b>.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> espessa e de cor vermelho-púrpura intensa<br/>
                    <b>Polpa:</b> firme, suculenta, com teor médio de fibra, tem coloração laranja-amarela<br/>
                    <b>Formato:</b> oval<br/>
                    <b>Vitaminas:</b> A e C, possui ainda Niacina e Tiamina.`,
                    attachments: [
                        {
                            name: 'Manga Tommy',
                            file: fichaMangaTommy
                        }
                    ]
                },
                {
                    name: "Melão Amarelo",
                    image: melaoAmarelo,
                    describe: `
                    O <b>melão amarelo</b>, originário da Ásia e aclimatado no Brasil, é a fruta do meloeiro, planta rasteira e herbácea, da família das Cucurbitáceas.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> enrugado, amarelo intenso<br/>
                    <b>Formato:</b> arredondado<br/>
                    <b>Polpa:</b> suculenta, branca e levemente esverdeada.`,
                    attachments: [
                        {
                            name: 'Melão Amarelo',
                            file: fichaMelaoAmarelo
                        }
                    ]
                },
                {
                    name: "Melão Pele de Sapo",
                    image: melaoSapo,
                    describe: `
                    Pertence à família Cucurbitácea, com características morfológicas de plantas herbáceas, cujo principal órgão utilizado é o <b>fruto</b>, o qual se constitui de uma baga geralmente grande cujas paredes externas endurecem e as internas permanecem carnosas.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> na cor verde escura e textura levemente rugosa<br/>
                    <b>Formato:</b> alongado<br/>
                    <b>Polpa:</b> firme e na cor branca.`,
                    attachments: [
                        {
                            name: 'Melão Pele de Sapo',
                            file: fichaMelaoSapo
                        }
                    ]
                },
            ],
            [
                {
                    name: "Polpa Integral de Goiaba Orgânica",
                    image: goiaba,
                    describe: `
                    <b>Polpa Integral de Goiaba Orgânica</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade interna da Sucus Brasil e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>BrixMínimo</b> -7,00 | ºB | MA CQ 001<br/>
                    <b>Acidez Mínimo</b> - 0,40 | % | MA CQ 002<br/>
                    <b>pH Mínimo</b> - 3,5 | MA CQ 004`,
                    attachments: [
                        {
                            name: 'Polpa Integral de Goiaba Orgânica',
                            file: fichaGoiabaOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Abacaxi Orgânico",
                    image: abacaxi,
                    describe: `
                    <b>Polpa de Abacaxi Orgânico</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> -12,00–14,00 | °B | MA CQ 001<br/>
                    <b>Acidez</b> -0,50–0,90 | % | MA CQ 002<br/>
                    <b>Ratio</b> - 13,30 – 28,00 | <br/>
                    <b>Brix/Acidez</b> - MA CQ 003 <br/>
                    <b>pH</b> < 4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 30 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Polpa de Abacaxi Orgânico',
                            file: fichAbacaxiOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Acerola Integral e Orgânica",
                    image: acerola,
                    describe: `
                    <b>Polpa de Acerola Integral</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 6,00–8,00 | ºB | MA CQ 001 <br/>
                    <b>Acidez</b> 1,00–1,60 | % | MA CQ 002 <br/>
                    <b>Ratio</b> 4,50 – 7,50 | <br/>
                    <b>Brix/Acidez</b> MA CQ 003 <br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>PolpaMin.</b> 30 | % | MA CQ 005<br/>
                    <b>Vitamina C</b> 1.100 Mg/100G`,
                    attachments: [
                        {
                            name: 'Polpa de Acerola Integral',
                            file: fichaAcerolaInt
                        },
                        {
                            name: 'Polpa de Acerola Orgância',
                            file: fichaAcerolaOrg
                        }
                    ]
                },
                {
                    name: "Suco de Limão Concentrado",
                    image: limao,
                    describe: `
                    <b>Suco de Limão Concentrado Congelado</b> obtido da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 50,0–55,0 | ºB | MA CQ 001 <br/>
                    <b>Vitamina C</b> 300 – 400 g/100 mL TRITATION <br/>
                    <b>pH</b> - 2,0 – 3,5 | MA CQ 004<br/>
                    <b>GLP</b> (°) 400 - 415 Acid X 10 X Densidad MA CQ 003 <br/>
                    <b>Essential Oil</b> (%v/v) Mín. 0,015 (%v/v) MA CQ 002 <br/>
                    <b>Pulp</b> 11,0-12,0 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Limão Concentrado',
                            file: fichaLimaoConcentrado
                        }
                    ]
                },
                {
                    name: "Polpa de Manga",
                    image: manga,
                    describe: `
                    <b>Polpa de Manga</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 12,00–14,00 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> 0,30-0,70 | % | MA CQ 002<br/>
                    <b>Ratio</b>17,00 - 46,70 Brix/Acidez MA CQ 003<br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 60 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Purê de manga convencional',
                            file: fichaMangaTommyInt
                        },
                        {
                            name: 'Purê de manga orgânico',
                            file: fichaMangaOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Maracujá Integral e Orgânico",
                    image: maracuja,
                    describe: `
                    <b>Polpa de Maracujá Integral</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade interna da Sucus Brasil legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 12,00 – 14,00 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> 2,80 – 4,00 | % | MA CQ 002<br/>
                    <b>Ratio</b> 3,00 - 5,00 Brix/Acidez MA CQ 003<br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 25 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Polpa de Maracujá Integral',
                            file: fichaMaracujaInt
                        },
                        {
                            name: 'Polpa de Maracujá Orgânico',
                            file: fichaMaracujaOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Mamão Integral e Orgânico",
                    image: mamao,
                    describe: `
                    <b>Polpa de Mamão Integral</b> obtida da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 10 - 14 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> (%) | 100g 0,40 – 0,70 (%) | 100g MA CQ 002<br/>
                    <b>Ratio</b> 14 – 35 brix/acidity g/100g citric acid MA CQ 003<br/>
                    <b>pH</b> 3,0 – 4,0 | MA CQ 004<br/>
                    <b>Polpa</b> 80 -98 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Polpa de Mamão Integral',
                            file: fichaMamaoInt
                        },
                        {
                            name: 'Polpa de Mamão Orgânico',
                            file: fichaMamaoOrg
                        }
                    ]
                },
                {
                    name: "Suco de Melancia Orgânica Congelada",
                    image: melancia,
                    describe: `
                    <b>Suco de Melancia Orgânica Congelada</b> obtida da parte comestível da mesma fruta sendo selecionada, processada sob condições adequadas de higiene, atendendo aos padrões de qualidade internos da Sucus Brasil.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 6,00 - 8,00 | °B | MA CQ 001<br/>
                    <b>Acidez</b> 0,20 – 0,30 | % | MA CQ 002<br/>
                    <b>pH</b> < 5,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 10 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Melancia Orgânica Congelada',
                            file: fichaMelanciaOrg
                        },
                    ]
                },
                {
                    name: "Purê de Manga Convencional e Orgânico",
                    image: manga,
                    describe: `
                    <b>Polpa de Manga</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 12,00–14,00 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> 0,30-0,70 | % | MA CQ 002<br/>
                    <b>Ratio</b>17,00 - 46,70 Brix/Acidez MA CQ 003<br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 60 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Purê de Manga Tommy',
                            file: fichaMangaPure1
                        },
                        {
                            name: 'Purê de Manga Palmer',
                            file: fichaMangaPure2
                        },
                    ]
                },
                {
                    name: "Suco de Laranja Concentrado",
                    image: laranja,
                    describe: `
                    <b>O Suco de Laranja Concentrado Congelado</b>, produto não fermentado obtido da parte comestível da fruta fresca e madura de Citrus sinensis L. Osbeck, é preparado por processos adequados, que mantêm as características físicas, químicas, organolépticas e nutricionais essenciais da fruta de onde provém. O suco é produzido de acordo com as Boas Práticas de Fabricação.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 65,5 – 66,5 | ºB | IFU no 08<br/>
                    <b>Ratio</b> 9,0-20,0 brix/acidez em g/100g de ácido cítrico anidro IFU no 03/ USDA<br/>
                    <b>Óleo Essencial</b> 0,006- 0,010 %v/v IFU no 45<br/>
                    <b>Polpa Min.</b> 8 -12 | % | IFU no 60`,
                    attachments: [
                        {
                            name: 'Suco de Laranja Concentrado',
                            file: fichaLaranjaConcentrado
                        },
                    ]
                },
                {
                    name: "Suco de Abacaxi Concentrado Congelado",
                    image: abacaxi,
                    describe: `
                    <b>Suco de Abacaxi Concentrado</b> a 60°Brix obtido da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 59,50- 60,50 | ºB | MA CQ 001<br/>
                    <b>ph</b> 3,00 – 4,20 | MA CQ 004<br/>
                    <b>Ratio</b> 15,00 – 35,00 brix/acidity g/100g citric acid MA CQ 003<br/>
                    <b>Acidez</b> (%) | 100g 1,70 – 4,00 (%) | 100g MA CQ 002<br/>
                    <b>Aditivos</b> Ácido ascórbico Max. 21,5 mg/ 100g MA CQ 002<br/>
                    <b>Polpa</b> 8,00 - 20,00 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Abacaxi Concentrado Congelado',
                            file: fichaAbacaxiConcentrado
                        },
                    ]
                },
                {
                    name: "Suco de Limão Congelado",
                    image: limao,
                    describe: `
                    <b>Suco de Limão Congelado</b>obtido da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.<br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 5,0 – 13,0 | ºB | MA CQ 001<br/>
                    <b>Vitamin C</b> 15,0 – 70,0 g/100 mL TRITATION<br/>
                    <b>pH</b> 2,0–3,5| MA CQ 004<br/>
                    <b>GLP(º)</b> 15,00-35,00 | Acid X10XDensidad | MA CQ 003<br/>
                    <b>Essential Oil</b> (%v/v) Mín. 0,015 (%v/v) MA CQ 002<br/>
                    <b>Polpa</b> 6,0 - 30,0 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Limão Congelado',
                            file: fichaSucoLimao
                        },
                    ]
                },
            ],
        ]

    },
    en: {
        //Menu
        titleMenu: 'Menu',
        closeMenu: 'Close Menu',
        menuHome: 'Home',
        menuAbout: 'About',
        menuProducts: 'Products',
        menuContact: 'Contact',

        //Home
        titleBanner: 'The best fruit for the world!',
        sloganHome: 'We work with Fresh Fruit and Fruit Pulp',
        subSloganHome: 'In Natura, Juice or Pulp',
        letterHome: 'The best fruit for the world!',

        //Sobre
        titleAbout: 'About',
        descAbout: 'We are a company founded in 2014, active in the industry and trade of ready-made juice, fruit pulp, fresh fruit and derivatives.',
        desc2About: 'We have our administrative headquarters in the city of Juazeiro do Norte – CE, where human, administrative and technical resources are managed.',
        missionTitle: 'Misison',
        missionDesc: 'Serve the domestic and foreign consumer market of fresh juices, pulps and fruits with quality products, striving for the total satisfaction of our customers.',
        visionTitle: 'Vision',
        visionDesc: 'To be a leader and reference in the production, trade and distribution of fresh juices, pulps and fruits.',
        valuesTitle: 'Values',
        valuesDesc: 'Responsibility, Quality and Trust.',
        titleWhereAbout: 'Where are we',

        //Produtos
        titleProducts: 'Our Products',
        box1Products: 'In Natura',
        box2Products: 'Juice/Pulp',


        //Contato
        titleContact: 'Contact',
        textContact: 'Choose how you prefer to contact us, we are ready to assist you.',
        titleFAQ: 'Common Questions',
        questionsFAQ: [
            {
                question: "Where is Sucus' administrative headquarters?",
                response: 'Our headquarters are in the city of <b>Juazeiro do Norte-CE</b>.',
            },
            {
                question: 'Which countries do you serve?',
                response: 'We serve the <b>European, North American and South American markets</b>.',
            },
            {
                question: 'Do you work with fresh fruit?',
                response: 'Yes, we work with <b>exports, imports and the domestic market</b>.',
            },
            {
                question: 'What fruits do you work with?',
                response: 'We work with the following <b>fresh fruits</b>: Pineapple, Butternut Squash Squash, Sergipana Squash, Banana Nanica, Sweet Potato Arapey, Canadian Sweet Potato, Sicilian Lemon, Tahiti Lemon, Apple, Mango Ataulfo, Mango Kent, Mango Palmer, Mango Tommy, Watermelon, Yellow Melon, Pele De Sapo Melon and Pera. <br/><br/><b>Juice and Pulp</b> we work with the following: Whole Pineapple, Organic Pineapple, Organic Acerola, Conventional Acerola, Organic Guava, Conventional Guava, Concentrated Orange, Whole Orange, Concentrated Lemon, Wholemeal Lemon, Mango Organic, Conventional Mango, Organic Papaya, Conventional Papaya, Conventional Passion Fruit and Organic Passion Fruit.',
            }
        ],

        //Proccess
        titleProccess: 'Proccess',
        descProccess: 'We follow all stages of the production process, from the fruit harvest to the final product. In this way we offer a product with the highest quality standard for our customers.',
        titleFruits: 'Selected Fruits',
        descFruits: 'Our fruits are selected and treated, with high hygiene and sanitization conditions, complying with hygienic and sanitary standards, based on international norms and legislation on food safety and quality assurance.',
        titleQuality: 'Quality product',
        descQuality: "Our employees and suppliers of raw materials, inputs and services are aware of the company's commitment to providing a total guarantee of quality to our final product.",
        sloganProccess: 'The execution of each of the operations is done with a focus on meeting the requirements for the <b>EXPORT AND INTERNAL MARKET</b>',

        //Sazonalidade
        titleSazonality: 'Seasonality',
        btnMore: 'More',
        months: [
            { value: 0, label: 'January' },
            { value: 1, label: 'February' },
            { value: 2, label: 'March' },
            { value: 3, label: 'April' },
            { value: 4, label: 'May' },
            { value: 5, label: 'June' },
            { value: 6, label: 'July' },
            { value: 7, label: 'August' },
            { value: 8, label: 'September' },
            { value: 9, label: 'October' },
            { value: 10, label: 'November' },
            { value: 11, label: 'December' }
        ],

        //Rodapé
        titleCertifieds: 'Certifications',
        titleWhere: 'Where are we',
        titleIACC: 'Donate to the IACC',
        descIACC: 'Institute of Support for Children with Cancer',
        btnIACC: 'Donate',
        titleSiteMap: 'Site map',
        textCopy: 'Copyright © 2021 Sucus Brasil | All rights reserved',



        //FRUTAS
        titleBtnFruits: 'Ficha(s) Técnica(s)',
        fruits: [
            [
                {
                    name: "Abacaxi",
                    image: abacaxi,
                    describe: `
                    <b>O Ananás ou abacaxi (Ananas comosus)</b> é uma infrutescência tropical produzida pela planta de mesmo nome. O abacaxi é uma fruta típica de países tropicais e subtropicais, portanto, não se adapta em regiões de clima frio, costuma florescer na época do inverno mas é ofertado no mercado durante o ano todo, em razão de ser plantado em diferentes locais, com diferentes técnicas de cultivo, incluindo diversos tipos de mudas e controle químico do florescimento. Os principais países produtores de abacaxi, segundo a Organização das Nações Unidas para a Alimentação e Agricultura (2008), são <b>o Brasil, a Tailândia, as Filipinas, a Costa Rica, a República Popular da China, a Índia e a Indonésia.</b>
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada e grossa<br/>
                    <b>Polpa:</b> levemente ácida<br/>
                    <b>Semente:</b> Não possui sementes<br/>
                    <b>Vitaminas:</b> rica em vitamina C, B1 e A.`,
                    attachments: [
                        {
                            name: 'Abacaxi',
                            file: fichaAbacaxi
                        }
                    ]
                },
                {
                    name: "Abóbora Butternut Squash",
                    image: aboboraButternut,
                    describe: `
                    <b>A Abóbora Butternut</b> é da espécie Cucurbita moschata. Os frutos atingem de 6 cm a 10 cm de diâmetro e 20 a 25 cm de comprimento. Pesam em torno de 1kg. <b>A abóbora é quase só polpa e possui pouca semente</b>.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> lisa e com cor de avelã clara<br/>
                    <b>Polpa:</b> laranja e ligeiramente açucarada<br/>
                    <b>Formato:</b> cilíndrico com pescoço de cor creme<br/>
                    <b>Vitaminas:</b> A e C, rica em antioxidantes e com alto teor de fibras.`,
                    attachments: [
                        {
                            name: 'Abóbora Butternut Squash',
                            file: fichaAboboraButternut
                        }
                    ]
                },
                {
                    name: "Abóbora Sergipana",
                    image: aboboraSergipana,
                    describe: `
                    A <b>Abóbora Sergipana</b>, também é conhecida como <b>Abóbora Jerimum de leite</b> ou Maranhão é cultivada principalmente no Norte e Nordeste do Brasil. Seu nome científico é Cucurbita moschata. A abóbora é o fruto da aboboreira, uma planta rasteira da mesma família da melancia e do pepino.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada<br/>
                    <b>Polpa:</b> de cor laranja acentuado, com alta textura e sabor<br/>
                    <b>Formato:</b> globular achatados com gomos característicos <br/>
                    <b>Vitaminas:</b> A, C, E, B1, B3 e B5`,
                    attachments: [
                        {
                            name: 'Abóbora Sergipana',
                            file: fichaAboboraSergipana
                        }
                    ]
                },
                {
                    name: "Banana Nanica",
                    image: banana,
                    describe: `
                    Originada na Ásia, somente foi conhecida na Europa e nos Estados Unidos no século 17. Típica fruta tropical, sua produção atual ocorre em grande parte dos países <b>Americanos,
                    Africanos e Asiáticos</b>. É uma das frutas mais importantes tanto em volume de produção, como em valor alimentar; além disso, é <b>comercializada o ano inteiro</b>.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada<br/>
                    <b>Polpa:</b> macia e doce<br/>
                    <b>Sementes:</b> Não possui sementes<br/>
                    <b>Vitaminas:</b> rica em fibras, potássio, vitaminas C e A`,
                    attachments: [
                        {
                            name: 'Banana Nanica',
                            file: fichaBanana
                        }
                    ]
                },
                {
                    name: "Batata Doce Aarapey ou Uruguaia",
                    image: batata_doceUruguaia,
                    describe: `
                    A <b>batata doce</b> é um vegetal rústico classificado como uma raiz tuberosa. Pode ser cultivada tanto em regiões de clima seco quanto de clima temperado. O ciclo médio dessa batata é 150 a 180 dias, podendo ser considerado precoce (120 dias) quando plantado nos meses mais quentes: Setembro a Fevereiro.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> possui uma película externa roxa e uma segunda película mais fina amarela<br/>
                    <b>Polpa:</b> de cor amarela e mais seca<br/>
                    <b>Semente:</b>  irregular e compridas<br/>
                    <b>Sabor:</b>  menos doce<br/>
                    <b>Vitaminas:</b> do complexo B e C, rica em carboidratos e fibras alimentares`,
                    attachments: [
                        {
                            name: 'Batata Doce Aarapey',
                            file: fichaBatataDoceArapey
                        }
                    ]
                },
                {
                    name: "Batata Doce",
                    image: batataDoce,
                    describe: `
                    A <b>batata doce</b> é um vegetal rústico classificado como uma raiz tuberosa. Pode ser cultivada tanto em regiões de clima seco quanto de clima temperado. O ciclo desta batata é considerado médio (150 a 180 dias) quando o plantio ocorre de Setembro a Fevereiro e longo (180 a 220 dias) nas demais épocas do ano.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> de cor roxa<br/>
                    <b>Polpa:</b> de cor branca<br/>
                    <b>Semente:</b>  alongado e uniforme<br/>
                    <b>Sabor:</b>  adocicado<br/>
                    <b>Vitaminas:</b> do complexo B e C, rica em carboidratos e fibras alimentares`,
                    attachments: [
                        {
                            name: 'Batata Doce',
                            file: fichaBatataDoceCanadense
                        }
                    ]
                },
                {
                    name: "Limão Siciliano",
                    image: limao2,
                    describe: `
                    O <b>limão-siciliano</b> (espécie Citrus x limon) é o fruto de uma pequena árvore de folha perene originária da região sudeste da Ásia, da família das rutáceas. O limão-siciliano cresce em muitas regiões quentes do mundo, como: <b>Índia, Argentina, Espanha, Irã e Estados Unidos (nos estados da Califórnia e do Arizona) são os maiores produtores</b>. Contém muitos compostos vegetais, minerais e óleos essenciais.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelada e grossa<br/>
                    <b>Polpa:</b> ácido suave<br/>
                    <b>Semente:</b> Possui sementes<br/>
                    <b>Vitaminas:</b> rica em vitaminas C, A e do complexo B<br/>
                    <b>Minerais:</b> Fonte de fósforo, ferro, magnésio e potássio.`,
                    attachments: [
                        {
                            name: 'Limão Siciliano',
                            file: fichaLimaoSiciliano
                        }
                    ]
                },
                {
                    name: "Limão Tahiti",
                    image: limao,
                    describe: `
                    O <b>limão Tahiti</b> é um fruto de origem tropical e no contexto mundial, os principais produtores
                    são o México, EUA (Flórida), Egito, Índia, Peru e Brasil.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> lisa ou ligeiramente rugosa, de coloração verde<br/>
                    <b>Polpa:</b> esbranquiçada, muito suculenta e de qualidade menos ácida<br/>
                    <b>Formato:</b> arredondado<br/>
                    <b>Sementes:</b> sem sementes Vitaminas: C, B1 e B2.`,
                    attachments: [
                        {
                            name: 'Limão Tahiti',
                            file: fichaLimaoTahiti
                        }
                    ]
                },
                {
                    name: "Pera",
                    image: pera,
                    describe: `
                    A <b>pera</b> é o fruto comestível da pereira, uma árvore do gênero Pyrus L., pertencente à família Rosaceae, e que conta com mais de três mil variedades de espécies cultivadas em todo o mundo.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> pode variar entre verde, amarela e dourada de acordo com o seu amadurecimento<br/>
                    <b>Polpa:</b> cor branca, macia-fundente, granulosa, doce, não ácida e sumarenta<br/>
                    <b>Vitaminas:</b> rica em magnésio, fósforo, selênio, fibras, vitaminas A, C e do complexo B<br/>
                    <b>Formato:</b> oval ou arredondado.`,
                    attachments: [
                        {
                            name: 'Pera',
                            file: fichaPera
                        }
                    ]
                },
                {
                    name: "Melancia",
                    image: melancia,
                    describe: `
                    A <b>melancieira é nativa da África</b>. Foi introduzida há muito no Brasil e se adaptou bem, tornando-se uma das frutas mais importantes do mercado, apesar de ser cultivada como uma hortaliça. O seu fruto é uma baga esférica, grande, com muitas sementes negras, também comestíveis, quando torradas.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> lisa na cor escura e clara (listras)<br/>
                    <b>Polpa:</b> vermelha e apresenta várias sementes pretas e pequenas na parte interna<br/>
                    <b>Formato:</b> arredondado (oval)`,
                    attachments: [
                        {
                            name: 'Melancia',
                            file: fichaMelancia
                        }
                    ]
                },
                {
                    name: "Maçã",
                    image: maca,
                    describe: `
                    A <b>Maçã</b> é uma das frutas mais antigas conhecidas, de origem caucasiana, na Ásia, ela é típica dos ambientes de clima temperado. De um paladar extremamente saboroso, ela é também detentora de um alto poder de nutrição.<br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> vermelha<br/>
                    <b>Polpa:</b> branca com poucas sementes pequenas<br/>
                    <b>Vitaminas:</b> C, E, B1 e B2.`,
                    attachments: [
                        {
                            name: 'Maçã',
                            file: fichaMaca
                        }
                    ]
                },
                {
                    name: "Manga Ataulfo",
                    image: mangaAtaulfo,
                    describe: `
                    A <b>manga Ataulfo</b>, também conhecida como manga de Mel, Manila, Amarelo, Bebê e
                    Champanhe é cultivada em áreas tropicais em todo o mundo, mas é original do México.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> amarelo dourado<br/>
                    <b>Polpa:</b> macia e firme, sem fibras<br/>
                    <b>Formato:</b> oval e plana<br/>
                    <b>Semente:</b> muito pequena.`,
                    attachments: [
                        {
                            name: 'Manga Ataulfo',
                            file: fichaMangaAtaulfo
                        }
                    ]
                },
                {
                    name: "Manga Kent",
                    image: mangaKent,
                    describe: `
                    A <b>Manga Kent</b> é uma cultivar de origem americana no ano de 1945, na Flórida. Derivada da Manga Brooks. Foi introduzida no Brasil na década de 60. Assim como a Palmer é de ciclo semi tardio.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> verde amarelado, corado de vermelho purpúreo<br/>
                    <b>Polpa:</b> polpa cremosa, sem fibras e muito doce<br/>
                    <b>Formato:</b> oval<br/>
                    <b>Minerais:</b> Cálcio, Zinco, Potássio, Ferro e Magnésio<br/>
                    <b>Vitaminas:</b> A, B, C e E.`,
                    attachments: [
                        {
                            name: 'Manga Kent',
                            file: fichaMangaKent
                        }
                    ]
                },
                {
                    name: "Manga Keitt",
                    image: mangaKeitt,
                    describe: `
                    A árvore provavelmente originária do noroeste da Índia e do norte da Birmânia e das ladeiras do Himalaia é uma espécie subtropical e tropical, sendo cultivada em algumas regiões de clima mediterrânico.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> na cor verde amarelado, corado de vermelho-róseo e com uma pele grossa<br/>
                    <b>Polpa:</b> cor amarela, alaranjada e suculenta, sem fibra<br/>
                    <b>Formato:</b> oval com ápice ligeiramente oblíquo<br/>
                    <b>Vitaminas:</b> A, B, C e E.`,
                    attachments: [
                        {
                            name: 'Manga Keitt',
                            file: fichaMangaKeitt
                        }
                    ]
                },
                {
                    name: "Manga Palmer",
                    image: mangaPalmer,
                    describe: `
                    A <b>Manga Palmer</b> tem origem Asiática, no entanto, os climas tropicais e subtropicais do Brasil permitem que ela tenha um bom desenvolvimento e produção. A árvore de nome científico Mangifera indica pertence à família das Anacardiaceae, a qual compreende cerca de 800 espécies, a mangueira, por sua vez, pode atingir 45 metros de altura.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> fina que podem variar entre gradientes de cores amarelas, rosas, vermelhas e verde<br/>
                    <b>Polpa:</b> amarelada, firme e com pouca ou nenhuma fibra<br/>
                    <b>Formato:</b> ovóide-oblongo, mais ou menos alongado, de acordo com a variedade<br/>
                    <b>Vitaminas:</b> A, C, B1, B2, B5 e sais como o fósforo, ferro e cálcio<br/>
                    <b>Sabor:</b> Doce.`,
                    attachments: [
                        {
                            name: 'Manga Palmer',
                            file: fichaMangaPalmer
                        }
                    ]
                },
                {
                    name: "Manga Tommy",
                    image: manga,
                    describe: `
                    Originária do sudoeste asiático, a mangueira (Mangifera indica L.) disseminou-se para várias regiões do mundo. Trata-se de uma fruta tropical de grande aceitação pelos consumidores em virtude de suas características exóticas e da composição nutricional. <b>A manga Tommy é uma das principais variedades cultivadas no Brasil em áreas comerciais</b>.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> espessa e de cor vermelho-púrpura intensa<br/>
                    <b>Polpa:</b> firme, suculenta, com teor médio de fibra, tem coloração laranja-amarela<br/>
                    <b>Formato:</b> oval<br/>
                    <b>Vitaminas:</b> A e C, possui ainda Niacina e Tiamina.`,
                    attachments: [
                        {
                            name: 'Manga Tommy',
                            file: fichaMangaTommy
                        }
                    ]
                },
                {
                    name: "Melão Amarelo",
                    image: melaoAmarelo,
                    describe: `
                    O <b>melão amarelo</b>, originário da Ásia e aclimatado no Brasil, é a fruta do meloeiro, planta rasteira e herbácea, da família das Cucurbitáceas.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> enrugado, amarelo intenso<br/>
                    <b>Formato:</b> arredondado<br/>
                    <b>Polpa:</b> suculenta, branca e levemente esverdeada.`,
                    attachments: [
                        {
                            name: 'Melão Amarelo',
                            file: fichaMelaoAmarelo
                        }
                    ]
                },
                {
                    name: "Melão Pele de Sapo",
                    image: melaoSapo,
                    describe: `
                    Pertence à família Cucurbitácea, com características morfológicas de plantas herbáceas, cujo principal órgão utilizado é o <b>fruto</b>, o qual se constitui de uma baga geralmente grande cujas paredes externas endurecem e as internas permanecem carnosas.
                    <br/><br/>
                    <b>CARACTERÍSTICAS DA FRUTA</b><br/>
                    <b>Casca:</b> na cor verde escura e textura levemente rugosa<br/>
                    <b>Formato:</b> alongado<br/>
                    <b>Polpa:</b> firme e na cor branca.`,
                    attachments: [
                        {
                            name: 'Melão Pele de Sapo',
                            file: fichaMelaoSapo
                        }
                    ]
                },
            ],
            [
                {
                    name: "Polpa Integral de Goiaba Orgânica",
                    image: goiaba,
                    describe: `
                    <b>Polpa Integral de Goiaba Orgânica</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade interna da Sucus Brasil e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>BrixMínimo</b> -7,00 | ºB | MA CQ 001<br/>
                    <b>Acidez Mínimo</b> - 0,40 | % | MA CQ 002<br/>
                    <b>pH Mínimo</b> - 3,5 | MA CQ 004`,
                    attachments: [
                        {
                            name: 'Polpa Integral de Goiaba Orgânica',
                            file: fichaGoiabaOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Abacaxi Orgânico",
                    image: abacaxi,
                    describe: `
                    <b>Polpa de Abacaxi Orgânico</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> -12,00–14,00 | °B | MA CQ 001<br/>
                    <b>Acidez</b> -0,50–0,90 | % | MA CQ 002<br/>
                    <b>Ratio</b> - 13,30 – 28,00 | <br/>
                    <b>Brix/Acidez</b> - MA CQ 003 <br/>
                    <b>pH</b> < 4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 30 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Polpa de Abacaxi Orgânico',
                            file: fichAbacaxiOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Acerola Integral e Orgânica",
                    image: acerola,
                    describe: `
                    <b>Polpa de Acerola Integral</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 6,00–8,00 | ºB | MA CQ 001 <br/>
                    <b>Acidez</b> 1,00–1,60 | % | MA CQ 002 <br/>
                    <b>Ratio</b> 4,50 – 7,50 | <br/>
                    <b>Brix/Acidez</b> MA CQ 003 <br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 30 | % | MA CQ 005<br/>
                    <b>Vitamina C</b> 1.100 Mg/100G`,
                    attachments: [
                        {
                            name: 'Polpa de Acerola Integral',
                            file: fichaAcerolaInt
                        },
                        {
                            name: 'Polpa de Acerola Orgância',
                            file: fichaAcerolaOrg
                        }
                    ]
                },
                {
                    name: "Suco de Limão Concentrado",
                    image: limao,
                    describe: `
                    <b>Suco de Limão Concentrado Congelado</b> obtido da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 50,0–55,0 | ºB | MA CQ 001 <br/>
                    <b>Vitamina C</b> 300 – 400 g/100 mL TRITATION <br/>
                    <b>pH</b> - 2,0 – 3,5 | MA CQ 004<br/>
                    <b>GLP</b> (°) 400 - 415 Acid X 10 X Densidad MA CQ 003 <br/>
                    <b>Essential Oil</b> (%v/v) Mín. 0,015 (%v/v) MA CQ 002 <br/>
                    <b>Pulp</b> 11,0-12,0 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Limão Concentrado',
                            file: fichaLimaoConcentrado
                        }
                    ]
                },
                {
                    name: "Polpa de Manga",
                    image: manga,
                    describe: `
                    <b>Polpa de Manga</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 12,00–14,00 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> 0,30-0,70 | % | MA CQ 002<br/>
                    <b>Ratio</b>17,00 - 46,70 Brix/Acidez MA CQ 003<br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 60 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Purê de manga convencional',
                            file: fichaMangaTommyInt
                        },
                        {
                            name: 'Purê de manga orgânico',
                            file: fichaMangaOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Maracujá Integral e Orgânico",
                    image: maracuja,
                    describe: `
                    <b>Polpa de Maracujá Integral</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade interna da Sucus Brasil legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 12,00 – 14,00 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> 2,80 – 4,00 | % | MA CQ 002<br/>
                    <b>Ratio</b> 3,00 - 5,00 Brix/Acidez MA CQ 003<br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 25 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Polpa de Maracujá Integral',
                            file: fichaMaracujaInt
                        },
                        {
                            name: 'Polpa de Maracujá Orgânico',
                            file: fichaMaracujaOrg
                        }
                    ]
                },
                {
                    name: "Polpa de Mamão Integral e Orgânico",
                    image: mamao,
                    describe: `
                    <b>Polpa de Mamão Integral</b> obtida da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 10 - 14 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> (%) | 100g 0,40 – 0,70 (%) | 100g MA CQ 002<br/>
                    <b>Ratio</b> 14 – 35 brix/acidity g/100g citric acid MA CQ 003<br/>
                    <b>pH</b> 3,0 – 4,0 | MA CQ 004<br/>
                    <b>Polpa</b> 80 -98 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Polpa de Mamão Integral',
                            file: fichaMamaoInt
                        },
                        {
                            name: 'Polpa de Mamão Orgânico',
                            file: fichaMamaoOrg
                        }
                    ]
                },
                {
                    name: "Suco de Melancia Orgânica Congelada",
                    image: melancia,
                    describe: `
                    <b>Suco de Melancia Orgânica Congelada</b> obtida da parte comestível da mesma fruta sendo selecionada, processada sob condições adequadas de higiene, atendendo aos padrões de qualidade internos da Sucus Brasil.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 6,00 - 8,00 | °B | MA CQ 001<br/>
                    <b>Acidez</b> 0,20 – 0,30 | % | MA CQ 002<br/>
                    <b>pH</b> < 5,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 10 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Melancia Orgânica Congelada',
                            file: fichaMelanciaOrg
                        },
                    ]
                },
                {
                    name: "Purê de Manga Convencional e Orgânico",
                    image: manga,
                    describe: `
                    <b>Polpa de Manga</b> obtida da parte comestível da fruta sendo os mesmos selecionados, processados sob condições adequadas de higiene e sanitização, atendendo aos padrões de qualidade e legislação em vigor.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 12,00–14,00 | ºB | MA CQ 001<br/>
                    <b>Acidez</b> 0,30-0,70 | % | MA CQ 002<br/>
                    <b>Ratio</b>17,00 - 46,70 Brix/Acidez MA CQ 003<br/>
                    <b>pH</b> <4,00 | MA CQ 004<br/>
                    <b>Polpa Min.</b> 60 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Purê de Manga Tommy',
                            file: fichaMangaPure1
                        },
                        {
                            name: 'Purê de Manga Palmer',
                            file: fichaMangaPure2
                        },
                    ]
                },
                {
                    name: "Suco de Laranja Concentrado",
                    image: laranja,
                    describe: `
                    <b>O Suco de Laranja Concentrado Congelado</b>, produto não fermentado obtido da parte comestível da fruta fresca e madura de Citrus sinensis L. Osbeck, é preparado por processos adequados, que mantêm as características físicas, químicas, organolépticas e nutricionais essenciais da fruta de onde provém. O suco é produzido de acordo com as Boas Práticas de Fabricação.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 65,5 – 66,5 | ºB | IFU no 08<br/>
                    <b>Ratio</b> 9,0-20,0 brix/acidez em g/100g de ácido cítrico anidro IFU no 03/ USDA<br/>
                    <b>Óleo Essencial</b> 0,006- 0,010 %v/v IFU no 45<br/>
                    <b>Polpa Min.</b> 8 -12 | % | IFU no 60`,
                    attachments: [
                        {
                            name: 'Suco de Laranja Concentrado',
                            file: fichaLaranjaConcentrado
                        },
                    ]
                },
                {
                    name: "Suco de Abacaxi Concentrado Congelado",
                    image: abacaxi,
                    describe: `
                    <b>Suco de Abacaxi Concentrado</b> a 60°Brix obtido da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.
                    <br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 59,50- 60,50 | ºB | MA CQ 001<br/>
                    <b>ph</b> 3,00 – 4,20 | MA CQ 004<br/>
                    <b>Ratio</b> 15,00 – 35,00 brix/acidity g/100g citric acid MA CQ 003<br/>
                    <b>Acidez</b> (%) | 100g 1,70 – 4,00 (%) | 100g MA CQ 002<br/>
                    <b>Aditivos</b> Ácido ascórbico Max. 21,5 mg/ 100g MA CQ 002<br/>
                    <b>Polpa</b> 8,00 - 20,00 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Abacaxi Concentrado Congelado',
                            file: fichaAbacaxiConcentrado
                        },
                    ]
                },
                {
                    name: "Suco de Limão Congelado",
                    image: limao,
                    describe: `
                    <b>Suco de Limão Congelado</b>obtido da parte comestível da fruta, sendo a mesma selecionada, processada em condições adequadas de higiene e saneamento, atendendo aos padrões internos de qualidade da Sucus Brasil e à legislação vigente.<br/><br/>
                    <b>CARACTERÍSTICAS FÍSICO QUÍMICAS</b><br/>
                    <b>Brix</b> 5,0 – 13,0 | ºB | MA CQ 001<br/>
                    <b>Vitamin C</b> 15,0 – 70,0 g/100 mL TRITATION<br/>
                    <b>pH</b> 2,0–3,5| MA CQ 004<br/>
                    <b>GLP(º)</b> 15,00-35,00 | Acid X10XDensidad | MA CQ 003<br/>
                    <b>Essential Oil</b> (%v/v) Mín. 0,015 (%v/v) MA CQ 002<br/>
                    <b>Polpa</b> 6,0 - 30,0 | % | MA CQ 005`,
                    attachments: [
                        {
                            name: 'Suco de Limão Congelado',
                            file: fichaSucoLimao
                        },
                    ]
                },
            ],
        ]
    }
}
