//IMAGENS
import goiaba from "./../../assets/fruits/goiaba.jpg";
import laranja from "./../../assets/fruits/laranja.jpg";
import melaoSapo from "./../../assets/fruits/melao_sapo.jpg";
import melaoAmarelo from "./../../assets/fruits/melao_amarelo.jpg";
import melancia from "./../../assets/fruits/melancia.jpg";
import abobora from "./../../assets/fruits/abobora.jpg";
import aboboraButternut from "./../../assets/fruits/abobora_butternut.jpg";
import aboboraSergipana from "./../../assets/fruits/abobora_sergipana.jpg";
import banana from "./../../assets/fruits/banana.jpg";
import maca from "./../../assets/fruits/maca.jpg";
import pera from "./../../assets/fruits/pera.jpg";
import abacaxi from "./../../assets/fruits/abacaxi.jpg";
import limao from "./../../assets/fruits/limao.jpg";
import batataDoce from "./../../assets/fruits/batata_doce.jpg";
import manga from "./../../assets/fruits/manga.jpg";
import acerola from "./../../assets/fruits/acerola.jpg";

export const fruits = [
    [
        //JANEIRO - melão amarelo, goiaba, laranja, melão pele de sapo e melancia.
        {
            name: "Melão Amarelo",
            image: melaoAmarelo,
        },
        {
            name: "Goiaba",
            image: goiaba,
        },
        {
            name: "Laranja",
            image: laranja,
        },
        {
            name: "Melão pele de sapo",
            image: melaoSapo,
        },
        {
            name: "Melancia",
            image: melancia,
        }
    ],
    [
        //Fevereiro - abóbora butternut squash, abóbora sergipana, banana nanica, goiaba, maçã, melancia, melão amarelo, melão pele de sapo e pera.
        {
            name: "Abóbora Butternut Squash",
            image: aboboraButternut,
        },
        {
            name: "Abóbora Sergipana",
            image: aboboraSergipana,
        },
        {
            name: "Banana Nanica",
            image: banana,
        },
        {
            name: "Goiaba",
            image: goiaba,
        },
        {
            name: "Maçã",
            image: maca,
        },
        {
            name: "Melancia",
            image: melancia,
        },
        {
            name: "Melão Amarelo",
            image: melaoAmarelo,
        },
        {
            name: "Melão pele de sapo",
            image: melaoSapo,
        },
        {
            name: "Pera",
            image: pera,
        }
    ],
    [
        //Março - abacaxi, abóbora butternut squash, abóbora sergipana, banana-nanica, goiaba, limão, maçã e pera.
        {
            name: "Abacaxi",
            image: abacaxi,
        },
        {
            name: "Abóbora Butternut Squash",
            image: aboboraButternut,
        },
        {
            name: "Abóbora Sergipana",
            image: aboboraSergipana,
        },
        {
            name: "Banana Nanica",
            image: banana,
        },
        {
            name: "Goiaba",
            image: goiaba,
        },
        {
            name: "Limão",
            image: limao,
        },
        {
            name: "Maçã",
            image: maca,
        },
        {
            name: "Pera",
            image: pera,
        }
    ],
    [
        //Abril - abóbora, abóbora butternut squash, abóbora sergipana, goiaba, limão, maçã e pera.

        {
            name: "Abóbora",
            image: abobora,
        },
        {
            name: "Abóbora Butternut Squash",
            image: aboboraButternut,
        },
        {
            name: "Abóbora Sergipana",
            image: aboboraSergipana,
        },
        {
            name: "Goiaba",
            image: goiaba,
        },
        {
            name: "Limão",
            image: limao,
        },
        {
            name: "Maçã",
            image: maca,
        },
        {
            name: "Pera",
            image: pera,
        }
    ],
    [
        //Maio - abóbora, abóbora butternut squash, abóbora sergipana, limão, maçã e pera.
        {
            name: "Abóbora",
            image: abobora,
        },
        {
            name: "Abóbora Butternut Squash",
            image: aboboraButternut,
        },
        {
            name: "Abóbora Sergipana",
            image: aboboraSergipana,
        },
        {
            name: "Limão",
            image: limao,
        },
        {
            name: "Maçã",
            image: maca,
        },
        {
            name: "Pera",
            image: pera,
        }
    ],
    [
        //Junho - abóbora butternut squash, abóbora sergipana, batata-doce, banana-nanica, limão e melancia.
        {
            name: "Abóbora Butternut Squash",
            image: aboboraButternut,
        },
        {
            name: "Abóbora Sergipana",
            image: aboboraSergipana,
        },
        {
            name: "Batata Doce",
            image: batataDoce,
        },
        {
            name: "Banana Nanica",
            image: banana,
        },
        {
            name: "Limão",
            image: limao,
        },
        {
            name: "Melancia",
            image: melancia,
        }
    ],
    [
        //Julho - laranja, banana-nanica e melancia.
        {
            name: "Banana Nanica",
            image: banana,
        },
        {
            name: "Laranja",
            image: laranja,
        },
        {
            name: "Melancia",
            image: melancia,
        }
    ],
    [
        //Agosto - banana-nanica, laranja, melancia, melão amarelo, melão pele de sapo, maçã e manga.
        {
            name: "Banana Nanica",
            image: banana,
        },
        {
            name: "Laranja",
            image: laranja,
        },
        {
            name: "Melancia",
            image: melancia,
        },
        {
            name: "Melão Amarelo",
            image: melaoAmarelo,
        },
        {
            name: "Melão Pele de Sapo",
            image: melaoSapo,
        },
        {
            name: "Maçã",
            image: maca,
        },
        {
            name: "Manga",
            image: manga,
        }
    ],
    [
        //Setembro - abacaxi, banana-nanica, laranja, melancia, melão amarelo e melão pele de sapo.
        {
            name: "Abacaxi",
            image: abacaxi,
        },
        {
            name: "Banana Nanica",
            image: banana,
        },
        {
            name: "Laranja",
            image: laranja,
        },
        {
            name: "Melancia",
            image: melancia,
        },
        {
            name: "Melão Amarelo",
            image: melaoAmarelo,
        },
        {
            name: "Melão Pele de Sapo",
            image: melaoSapo,
        }
    ],
    [
        //Outubro - abacaxi, acerola, banana-nanica, laranja, manga, melancia, melão amarelo e melão pele de sapo.
        {
            name: "Abacaxi",
            image: abacaxi,
        },
        {
            name: "Acerola",
            image: acerola,
        },
        {
            name: "Banana Nanica",
            image: banana,
        },
        {
            name: "Laranja",
            image: laranja,
        },
        {
            name: "Manga",
            image: manga,
        },
        {
            name: "Melancia",
            image: melancia,
        },
        {
            name: "Melão Amarelo",
            image: melaoAmarelo,
        },
        {
            name: "Melão Pele de Sapo",
            image: melaoSapo,
        }
    ],
    [
        //Novembro - abacaxi, banana-nanica, laranja, manga, melancia, melão amarelo e melão pele de sapo.
        {
            name: "Abacaxi",
            image: abacaxi,
        },
        {
            name: "Banana Nanica",
            image: banana,
        },
        {
            name: "Laranja",
            image: laranja,
        },
        {
            name: "Manga",
            image: manga,
        },
        {
            name: "Melancia",
            image: melancia,
        },
        {
            name: "Melão Amarelo",
            image: melaoAmarelo,
        },
        {
            name: "Melão Pele de Sapo",
            image: melaoSapo,
        }
    ],
    [
        //Dezembro - banana-nanica, laranja, manga, melancia, melão amarelo e melão pele de sapo.
        {
            name: "Banana Nanica",
            image: banana,
        },
        {
            name: "Laranja",
            image: laranja,
        },
        {
            name: "Manga",
            image: manga,
        },
        {
            name: "Melancia",
            image: melancia,
        },
        {
            name: "Melão Amarelo",
            image: melaoAmarelo,
        },
        {
            name: "Melão Pele de Sapo",
            image: melaoSapo,
        }
    ]
]
