import React from "react";

//Images
import processo from "./../../assets/processo.png";
import frutas from "./../../assets/frutas_selecionadas.png";
import produtos from "./../../assets/produtos_qualidade.png";

import { language } from "./../../_constants";

//styles
import "./styles.scss";

const Features = (props) => {

    const lang = language[props.language] ? language[props.language] : language['pt-br'];
    return (
        <section className="features">
            <div className="item-feature">
                <img className="img-feature" src={processo} width="320" alt="Carregamento de frutas" />
                <div>
                    <h2>{lang.titleProccess}</h2>
                    <p>
                        {lang.descProccess}
                    </p>
                </div>
            </div>
            <div className="item-feature inverse">
                <img className="img-feature" src={frutas} width="320" alt="Manga" />
                <div>
                    <h2>{lang.titleFruits}</h2>
                    <p>
                        {lang.descFruits}
                    </p>
                </div>
            </div>
            <div className="item-feature">
                <img className="img-feature" src={produtos} width="320" alt="Mangas" />
                <div>
                    <h2>{lang.titleQuality}</h2>
                    <p>
                        {lang.descQuality}
                    </p>
                </div>
            </div>
            <p className="text-main-features" dangerouslySetInnerHTML={{ __html: lang.sloganProccess }}></p>
        </section>
    );
}

export default Features;
