import React from "react";
import Footer from "../../_components/Footer";
import Menu from "../../_components/Menu";
import BoxProduct from "../../_components/BoxProduct";
import Modal from "../../_components/Modal";
import { language } from "../../_constants";

//images
import imgBanner from "./../../assets/banner_products.jpg";

//styles
import "./styles.scss";
import { FaFileDownload } from "react-icons/fa";


const Products = (props) => {
    const lang = language[props.match.params.lang] ? language[props.match.params.lang] : language['pt-br'];

    const [showProduct, setShowProduct] = React.useState(false);
    const [product, setProduct] = React.useState({});

    const [activeCategory, setActiveCategory] = React.useState(1);

    return (
        <>
            <Menu language={props.match.params.lang} />
            <section className="banner-products">
                <img src={imgBanner} alt="Frutas" className="img-banner" />
                <div className="box-products">
                    <h1 className="title-page">{lang.titleProducts}</h1>
                </div>
            </section>
            <section className="box-categories">
                <div className="box-btns-abas">
                    <button className={`btn-aba-1 btn-aba ${activeCategory === 1 && 'active'}`} onClick={() => setActiveCategory(1)} type="button">{lang.box1Products}</button>
                    <button className={`btn-aba-2 btn-aba ${activeCategory === 2 && 'active'}`} onClick={() => setActiveCategory(2)} type="button">{lang.box2Products}</button>
                </div>
                {
                    activeCategory === 1 &&
                    <div className="list-products" id="inNatura">
                        {
                            lang.fruits[0].map((fruit, idx) => <div key={`fruit-${idx}`} className="item-product">
                                <BoxProduct
                                    onClick={() => {
                                        setProduct(fruit);
                                        setShowProduct(true);
                                    }}
                                    name={fruit.name}
                                    image={fruit.image}
                                    btnMore={lang.btnMore}
                                    showButton={true}
                                />

                            </div>)
                        }
                    </div>
                }
                {

                    activeCategory === 2 &&
                    <div className="list-products" id="sucos">
                        {
                            lang.fruits[1].map((fruit, idx) => <div key={`fruit-${idx}`} className="item-product">
                                <BoxProduct
                                    onClick={() => {
                                        setProduct(fruit);
                                        setShowProduct(true);
                                    }}
                                    name={fruit.name}
                                    image={fruit.image}
                                    btnMore={lang.btnMore}
                                    showButton={true}
                                />

                            </div>)
                        }
                    </div>
                }
            </section>
            <Modal
                isOpen={showProduct}
                onRequestClose={() => setShowProduct(false)}
            >
                <div className="content-details-fruit">
                    <h3>{product.name}</h3>
                    <div dangerouslySetInnerHTML={{ __html: product.describe }} />
                    <h4>{lang.titleBtnFruits}</h4>
                    <div className="row-buttons">
                        {
                            product?.attachments?.map(f =>
                                <a href={f.file} key={`fruit-${f.name}`} target="_blank" rel="noreferrer" className="btn-download">
                                    <FaFileDownload size={16} /> {f.name}
                                </a>
                            )
                        }
                    </div>

                </div>
            </Modal>
            <Footer language={props.match.params.lang} />
        </>
    );
}

export default Products;
